import React from 'react';
import { useDispatch } from 'react-redux';
import * as TicketAction from '../../../actions/ticketAction';
import { useIntl } from 'react-intl';
import { FcGoogle } from 'react-icons/fc';
import { useGoogleLogin, useGoogleOneTapLogin, GoogleOAuthProvider } from '@react-oauth/google';


const GoogleLoginComponent = ({ logon, setGoogleLoging, inviteCode, showButton, emailOptIn }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      setGoogleLoging && setGoogleLoging(true);
      dispatch(TicketAction.oauth_sign_in({ tokenInfo: tokenResponse, inviteCode, emailOptIn }, logon));
    },
  });


  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      dispatch(TicketAction.oauth_sign_in_credential({ credential: credentialResponse.credential, inviteCode, emailOptIn }, logon));
    },
    onError: () => {
      console.log('Login Failed');
    },
  });


  let loginButton = showButton ? <button
    type="button"
    className='loginMethodButton'
    onClick={login}
  >
    <FcGoogle style={{ padding: 6 }} size={24} /> {intl.formatMessage({ id: 'sign_in_with' }, { method: intl.formatMessage({ id: 'google' }) })}
  </button> : <></>;

  return loginButton;
};

export const GoogleLogin = ({ logon, setGoogleLoging, inviteCode, showButton }) => {
  return <GoogleOAuthProvider
    clientId={'988058218123-enpfsi0n6fo9jqa2aqfr6s37t16loth8.apps.googleusercontent.com'}
  >
    <GoogleLoginComponent 
      logon={logon}
      setGoogleLoging={setGoogleLoging}
      inviteCode={inviteCode}
      showButton={showButton}
    />
  </GoogleOAuthProvider>
};
