export default {
  cn: {
    title: '标题',
    untitled: '未命名',
    sidebarTitle: '方块',
    team_space: '的团队空间',
    personal_space: '的个人空间',
    workspace: '工作空间',
    workspace_tooltip: '团队成员可访问的页面',
    private: '私有页面',
    private_tooltip: '仅限您自己可访问的页面',
    shared: '共享页面',
    shared_tooltip: '只有您授权的用户才能访问的页面',
    ril: '稍后阅读',
    back: '返回',
    close: '关闭',
    cancel: '取消',
    confirm: '确认',
    open: '打开',
    create: '创建',
    confirm_done: '确认',
    loadmore: '加载更多',
    new_page: '新建页面',
    refresh: '刷新',

    get_title: '获取标题',

    more_menu: '点击查看更多功能',
    export: '导出',
    export_formats_doc: 'PDF, Word, Markdown, Html',
    export_formats_db: 'CSV',
    export_formats_slides: 'PDF, Markdown',
    export_format: '导出文件格式',
    export_no_data: '没有可导出数据',
    export_slides_notes: '演讲者笔记',
    excluded: '不导出',
    separated: '单独页面',
    same_page: '同一页',


    import: '导入',
    import_settings: '导入设置: ',
    csv_header_confirm: '以csv表头为数据库表头',
    csv_column: '第{num}列',
    csv_first_line: 'CSV文件中第一行如下: ',

    error_msg: '抱歉，出错啦!',

    invite_code: '邀请码',
    invite_friends: '邀请好友',
    invite_friends_desc: '奖励FunBlocks AI币',
    or_invite_friend_rewards: '或者邀请好友，获得奖励',
    copy_intruction: '复制以下内容发送给朋友:',
    homepage: '访问主页',
    feedback: '建议或问题反馈',
    reply: '回复',
    notice: '通知',
    new_version_available: '发现新版本，请点击下方按钮更新',
    upgrade_now: '立即更新',
    upgrade_plan: '升级会员',
    err_catched: '发生错误，请点击下方按钮重新加载',
    reload: '重新加载',

    new_tag: '新标签',

    slides: '幻灯片',
    db: '数据源',
    doc: '页面',
    flow: 'AI 思维导图',
    ai_flow: 'AI 无限白板 + 思维导图',
    ai_slides: 'AI 幻灯片',
    ai_infographics: 'AI 洞察图',

    notag_label: '无 {label} 数据',

    share: '分享',
    edit: '编辑',
    comment: '评论',
    full_access: '全部权限',
    full_access_description: '可以编辑、分享',
    can_edit: '可编辑',
    can_edit_description: '可以编辑，但不能分享给他人',
    can_comment: '可评论',
    can_comment_description: '可以评论，但不能编辑',
    can_view: '可查看',
    can_view_description: '但不能评论、编辑',

    copy_link: '复制链接',
    duplicate: '复制',
    copied: '已成功复制',
    copy_failed: '复制失败',

    history: '编辑历史',
    restore_doc_tip: '恢复到这一版本？',
    restore_doc_tooltip: '本操作将覆盖当前文档的所有内容',
    no_history_doc_selected: '没有选中任何历史文档',
    doc_history: '页面编辑历史',

    doc_play: '将本文转化为PPT',
    slides_seminar: '会议模式',
    slides_show: '幻灯片模式',
    slides_editor: '制作视频',
    slides_ai_converter: '由AI转成幻灯片',

    open_to_public: '公开页面给所有人',
    everyone_at_org: '空间全体成员',
    everyone_at_group: '小组所有成员',

    trashbin: '垃圾箱',
    trashbin_tooltip: '将上方页面拖拽到此处，即可放入垃圾箱',
    ril: '稍后阅读',
    settings: '账号及成员管理',
    settings_account: '账号相关',
    settings_workspace: '空间相关',

    settings_my_account_menu: '我的账户',
    settings_my_account_title: '我的账户',
    settings_ril_menu: '方块笔记',
    settings_ril_title: '方块笔记插件设置',
    settings_ril_workspace_selection: `选择一个工作空间关联"方块笔记"`,
    settings_flow_title: 'Flow白板操作设置',
    settings_flow_menu: 'Flow白板操作设置',
    settings_more_menu: '更多设置',
    settings_more_title: '更多设置',
    settings_api_enable_api: '直接访问大语言模型(LLM) API',
    self_owned_ai_api: '私有LLM API Key',
    llm_api_token_guide: '您可以登录{aiProvider}网站，获取API Key',
    llm_api_key: 'API Key',
    settings_language_desc: '选择您的软件界面语言',
    settings_api_menu: 'LLM 服务提供者',
    settings_api_title: '大语言模型(LLM)接口设置',
    settings_api_llms: 'LLM服务方',
    settings_api_choose_model: '选择模型',
    settings_api_proxy: '服务网址(或代理网址)',
    openai_compatible:
      '### 使用兼容OpenAI接口的LLM服务时，只需：\n1. "服务地址"中输入接口地址；\n2. 选择"其他"模型，并填入模型名称。',
    other: '其他',
    settings_api_desc: '### 开启并设置大语言模型(LLM) API Key后：\n1. FunBlocks AI将以您提供的API Key直接访问选定的大模型，不受FunBlocks AI访问次数限制。 \n2. API Key在本地保存，不会上传到FunBlocks服务端，无需担心被盗用。',
    invalid_api_settings: '您已设置使用第三方LLM服务，但没有完成相关设置，请重新检查',
    has_ai_api: '有LLM API Key?',
    settings_prompts_menu: '自定义AI应用',
    settings_prompts_title: '自定义AI应用管理',
    add_prompt: '添加应用',
    developer: '开发者',
    description: '描述',
    task_content: 'Prompt变量来自',
    task_content_from_selected: '文档中选定的内容',
    task_content_from_input: '单文本框输入',
    task_content_from_form: '自定义表单输入',
    task_content_form: "定制Prompt界面输入项",
    task_content_form_desc: "AI在执行Prompt时，需要了解用户的具体需求。将这些需求分解成多个输入项，方便用户理解和输入，也让AI更准确地理解需求，从而生成更满意的结果。",
    advanced_options: '高级选项',
    context_usage: '使用/处理上下文内容',
    context_usage_desc: `AI助手在执行Prompt时，会自动根据应用场景选择并使用上下文内容。你也可以在Prompt中指定如何使用上下文内容，只需在Prompt中包含'{{selected_text}}'。例如："给定收到的消息或评论内容:{{selected_text}}。\n请阅读以上内容，理解发送者的意图，并写一个回复，回应发送者的意图和其他要求。"`,
    ask_ai_about_selected: '给定内容',
    ask_ai_about_selected_tooltip: '指定AI处理给定的文本',
    ask_ai_about_given_text_hint: '请输入或粘贴要处理的文本',

    prompt_template: 'AI Prompt',
    prompt_title: '标题',
    prompt_title_placeholder: '用描述性标题让用户更容易理解Prompt的功能',
    prompt_desc_placeholder: '对AI应用的描述，例如，它能做什么等...',
    prompt_template_desc: '对AI的提示语(Prompt)，在其中可以包含{selected_text}{variables}。AI助手在执行时，会将 {selected_text} 替换成给定的上下文内容',
    prompt_template_desc_1: "AI助手在执行时，会自动将用户输入的内容添加到Prompt中。类似于{{selected_text}}，你也可以在Prompt中指定如何处理用户输入的内容：{variables}",
    prompt_template_placeholder: `针对AI的Prompt内容,例如：我希望你扮演一个创意作家的角色。我将为你提供一个段落，你的任务是根据其意图进行续写。在保持原文的语气和风格的基础上，注入你的想象力和连贯性。你的续写应该为故事增添深度和细节，吸引读者的注意力和兴趣。`,
    prompt_template_fixed_placeholder: '对AI的Prompt内容,例如：我希望你扮演一个脱口秀演员。给我说个笑话吧',
    prompt_no_text_err: `请确认你的Prompt中包含：{variables}`,
    prompt_content_placeholder: '让AI处理的主题或内容',
    prompt_content_label: '主题',
    prompt_context: '指令上下文',
    prompt_context_desc: '为AI助手执行指令提供相关内容',
    context_no_doc_choosen: '请选择相关页面',
    context_doc_no_content: '选择的页面没有可读取内容',
    context: '相关素材',
    current_doc: '当前页面',
    choose_a_doc: '选择一个页面',
    none: '无',
    settings_space_menu: '空间设置',
    settings_space_title: '空间设置',
    settings_members_menu: '成员管理',
    settings_labs_menu: '实验室',
    settings_db_capability_switch: '多视图数据库功能',
    service_subscribe: '升级会员',
    service_subscribe_ai: '升级 AI 会员',
    service_subscribe_funblocks: '升级 FunBlocks 会员',
    service_subscribe_title: '升级会员资格',
    service_product_name: '{service}{level}会员',
    service_market: '购买 AI 币',
    price: '价格',
    num: '数量',
    name: '名称',
    subscribe: '订阅',
    upgrade: '升级',

    pin: '收藏',
    pinned: '已收藏',
    visibility: '对谁可见',
    visibility_private: '自己(私有)',
    visibility_public: '所有人(公共)',
    visibility_workspace: '同事(同一工作空间)',
    prompts_mine: "我的应用",
    prompts_mine_desc: '你可以在这里制作自己的AI助手应用，或者从公共应用中选择适合你的应用。',
    prompts_developed: '我开发的应用',
    prompts_public: "公开应用",
    prompts_workspace: '工作空间应用',
    prompts_pinned: "精选的应用",
    prompts_pinned_desc: "您可以从公开或共享的应用中收藏您喜欢的，AI助手会在菜单中显示您收藏的应用，随时供您选择执行。",
    prompts_validate: "待审核应用",
    prompts_drafter: "内置撰写类应用",
    prompt_qualified: "合格",
    prompt_not_qualified: "不合格",
    prompt_no_arg: '固定Prompt，不包含用户输入内容',
    run_prompt: "执行",
    prompt_language: "应用描述语言",
    all: '所有',
    arg_type: '类型',
    arg_type_textline: '文本框',
    arg_type_input: '文本框',
    arg_type_text: '多行文本框',
    arg_type_textarea: '多行文本框',
    arg_type_select: '下拉选择',
    select_option_free_input: '允许用户自行输入选项之外的值',
    no_suitable_option: '没有合适的选项',
    user_input_option: '输入你自己的选项值',
    select_option_placeholder: '选项',
    arg_name: '变量名',
    arg_label: '标题',
    arg_hint: '提示',
    arg_required: '必填',
    arg_optional: '可选',
    arg_default_value: '默认值',
    yes: '是',
    no: '否',
    arg_label_placeholder: '表单项名称',
    arg_hint_placeholder: '向用户展示填入此表单项的合适内容，可以举例',
    arg_default_value_placeholder: '展示给用户的默认值',
    add_arg: '增加输入项',
    clear_arg: '重置',
    update: '更新',
    generate_outline: '让AI生成提纲',
    no_topic: '请提供要撰写的主题',
    prompt_blocked_safety: '在任务提示中包含危险或不当信息',
    unknown_failure: '未知错误',

    prompt_app: 'FunBlocks Prompt 应用',
    prompt_unpin_tooltip: '取消应用收藏，并从AI助手菜单移除',
    prompt_pin_tooltip: '收藏应用，并展现在AI助手菜单中',
    prompt_clone_tooltip: '复制/克隆应用',
    prompt_edit_tooltip: '编辑应用',
    prompt_delete_tooltip: '删除应用',
    link_copied: '分享链接已复制到剪切板',
    prompt: 'Prompt',
    confirm_open_ai_generated_slides: '已生成幻灯片，保存在私有空间，继续编辑？',

    CRUD: '增/删/改',
    not_provided: '未提供',
    new: '新建',
    clone: '复制/克隆',

    service_name_ril: '方块笔记',
    service_name_funblocks: 'FunBlocks',
    service_name_xslides: '幻灯片',
    service_name_ai: 'FunBlocks AI ',
    service_name_aiplus: 'AI Premium',

    checkout_form_title_aiplus: '获得更强大的FunBlocks AI',
    checkout_form_title_funblocks: '升级到{level}会员',
    checkout_form_title_aiinsights: '获得强大的AI洞察力',
    checkout_total: '应付总额',
    sub_total: '小计',

    funblocks_product_slogan: '用FunBlocks提升你的工作效率',
    funblocks_promotion_slogan: '我们提供市场上最具成本效益的解决方案之一！',
    funblocks_ai_product_slogan: '解锁您无限的潜能，尽情体验 FunBlocks AI',
    funblocks_ai_promotion_slogan: '我们提供市场上最具成本效益的解决方案之一！',
    funblocks_aiinsights_promotion_slogan: '我们提供市场上最具创新的AI学习与生产力工具！',
    yearly: '每年',
    monthly: '每月',
    cost_save: '省 ',
    upgrade_to_vip: '升级您的会员',
    purchase_ai: 'Purchase AI Premium',
    cancel_subscription: '取消会员订阅',
    billed_period: '订阅周期',
    trial_vip: '{trial_days}天免费试用',
    trial_desc: '您将免费试用VIP会员{trial_days}天，在次期间无需付费',
    trial_confirm: '开始免费试用',
    wont_charge_today: '您今天不会被扣费',
    order_summary: '小结',

    service_level_free: '免费版',
    service_level_vip: 'VIP',
    service_level_plus: 'Plus',
    service_level_premium: 'Premium',
    service_level_pro: 'Pro',
    service_level_unlimited: 'Unlimited',
    service_level_smallteam: '小团队',
    service_level_enterprise: '企业',

    supports: '支持',
    advantage_of_product: '{product} 会员享有',
    generate_insights_cards: '生成AI洞察卡片',
    generate_insights_cards_quota: '每日可生成AI洞察图片: {quota} 次',
    more_queries_per_day: '每日更多AI助手访问次数',
    query_quota: '{model_level}: {quota} 次',
    unlimited: '无限',
    advanced_features: '高级功能',
    writing_assistant: 'AI 写作助手',
    reading_assistant: 'AI 阅读助手',
    other_llm_providers_enabled: '利用自有 LLM API Key 访问AI助手',
    other_llm_providers_enabled_desc: '支持 OpenAI GPT、Claude、Gemini系列接口，且不限制访问每日次数',
    build_ai_apps: '自定义 AI 助手命令功能',
    other_benefits: '其他好处',
    technical_support: '优先的技术支持',
    no_traffic_control: '在高流量时期没有请求限制',
    more_capacity: '更大容量',
    memos_capacity: '每日可存储的 Memo 数量: {quota}',
    memos_capacity_desc: '支持一键保存AI助手生成的内容到 Memo，也可直接保存选中的网页内容到 Memo。',
    blocks_capacity: '每月可存储block数量: {quota}',
    blocks_capacity_desc: 'Block是构成 FunBlocks 页面内容的基本单位，就像乐高积木是乐高城堡的基本单位一样。它们可以是文本、图像、视频、表格、代码片段或任何其他类型的内容。',
    team_size: '最大团队人数: {quota}',
    markdown_slides: 'Markdown 写幻灯片',
    markdown_slides_desc: '支持用Markdown格式创建幻灯片，简便高效，配备AI写作助手，助你高效创作和学习',
    doc_to_slides: '文档一键生成幻灯片',

    buyable_plans: '可订阅的会员产品',
    privileges: '会员功能',
    privileged_feature: '此功能为会员功能',
    current_plan: '当前会员等级',
    plan_expire_at: '有效期至 {date}',
    last_plan_expired: '你的{plan}会员已于{date}过期',
    current_plan_expire_at: '您的{plan}服务将到期于 {date}',
    buy: '立即购买',
    paid_to_check: '如果已支付，请点击按钮',
    subscribe_status_updated: '会员状态已更新',
    aicoins_status_updated: 'AI币余额已更新',
    goto_vip_buy_page: '加入会员',
    aicoin: 'AI币',
    exceeded_quota: '您已经达到当前会员使用限量',

    choose_login_method: '其他登录方法',
    sign_in: '登入FunBlocks',
    sign_in_with: '以{method}登入',
    sign_in_with_password: '以账号密码注册/登入',
    sign_in_with_email_vcode: '以邮箱验证码登入',
    google: 'Google帐号',
    password_account: '密码',
    login_signin_form_info: '登录您的FunBlocks账号',
    login_signin_vcode_form_info: '登入或创建您的FunBlocks账号',
    login_resetpswd_form_info: '输入验证码重置您的密码',
    login_signup_form_info: '注册您的FunBlocks账号',
    forgotpswd: '忘记密码？',
    hadaccount: '已有账号？',
    login: '登录',
    vcode_login: '邮箱验证码登录',
    not_registered: '未注册？',
    password_login: '密码登录',
    logout: '退出登录',
    signup: '注册',
    resetpswd: '重置密码',
    register_now: '立即注册',
    signup_info: '还没有FunBlocks账号？一分钟即可完成注册，立即体验使用FunBlocks AI写作和学习的乐趣。',
    getvcode: '获取验证码',
    verification_code: '验证码',
    vcode_err: '验证码错误，请重新填写，如果看不清，请点击图片换一个',
    phone: '手机号',
    email: '邮箱',
    phone_or_email: '手机号或邮箱',
    nickname: '昵称',
    captcha: '图形验证码',
    password: '密码',

    rename: '重命名',
    insert_right: '向右插入',
    insert_left: '向左插入',
    delete_property: '删除字段',
    confirm_delete_property: '确认删除【{label}】字段？确认后将删除【{label}】字段及其数据。',
    property_type: '字段类型',
    hide_property: '隐藏字段',
    add_property: '添加字段',

    confirm_delete_item: '删除后无法恢复，确认删除吗？',

    sort: '排序',
    sorts: '排序',
    sort_ascending: '升序',
    sort_descending: '降序',
    add_sort: '添加排序',
    delete_sort: '删除排序',
    operation_need_to_delete_sort: '本视图正处于设置的排序中，进行本操作请先删除排序设置，确认吗？',
    asc: '升序',
    desc: '降序',

    filter: '筛选',
    filters: '筛选',
    add_filter: '添加筛选',
    delete_filter: '删除筛选',
    delete_filter_rule: '删除筛选规则',
    delete_filter_group: '删除筛选组',
    filter_where: '筛选条件',
    filter_and: '并且',
    filter_or: '或者',
    add_filter_rule: '添加筛选规则',
    add_filter_group: '添加筛选组',
    turn_into_filter_group: '转换为筛选组',

    equals: '等于',
    not_equals: '不等于',
    less_than: '小于',
    less_than_or_equal: '小于等于',
    greater_than: '大于',
    greater_than_or_equal: '大于等于',
    contains: '包含',
    not_contains: '不包含',
    starts_with: '开头是',
    ends_with: '结尾是',
    is_empty: '为空',
    is_not_empty: '不为空',
    is_true: '为真',
    is_false: '为假',
    date_greater_than: '晚于',
    date_greater_than_or_equal: '晚于等于',
    date_less_than: '早于',
    date_less_than_or_equal: '早于等于',
    date_between: '位于',

    type_title: '标题',
    type_text: '文本',
    type_number: '数字',
    type_date: '日期',
    type_person: '成员',
    type_select: '单选',
    type_multi_select: '多选',
    type_checkbox: '勾选框',
    type_email: '邮箱',
    type_phone: '电话',
    type_url: '网址链接',

    type_created_at: '创建时间',
    type_updated_at: '最近编辑时间',
    type_created_by: '创建人',
    type_updated_by: '最近编辑者',
    type_tags: '标签',

    advanced_type: '高级类型',
    basic_type: '基础类型',

    view_config: '视图',
    view_settings: '视图设置',
    timelineby: '任务时间字段',
    use_time_pair: '采用开始和结束时间',
    timeline_start: '开始时间',
    timeline_end: '结束时间',
    timeline_color: '时间轴颜色',

    info_set_timeline_title: '时间视图没有设置时间字段',
    info_set_timeline_content: '请在"视图配置"中选择任务时间字段，以便查看任务的时间轴。',

    has_end_date: '有结束日期',
    has_time: '包含具体时间',
    date_format: '日期格式',

    clear_cell_data: '清除',

    date_format_ymd_dash: '年-月-日',
    date_format_mdy_dash: '月-日-年',
    date_format_dmy_dash: '日-月-年',
    date_format_ymd_slash: '年/月/日',
    date_format_mdy_slash: '月/日/年',
    date_format_dmy_slash: '日/月/年',

    view_timeline_by_day: '按天查看',
    view_timeline_by_week: '按周查看',
    view_timeline_by_month: '按月查看',
    view_timeline_by_quarter: '按季查看',
    view_timeline_by_year: '按年查看',
    today: '今天',

    select_options: '选项',
    add_option: '添加选项',
    options_search_placeholder: '搜索或输入选项',
    option_input_placeholder: '请输入',
    option_delete: '删除选项',
    not_enough_options: '请添加至少两个选项',
    not_null: '{field}不能为空',

    color_whitesmoke: '白灰色',
    color_lightgray: '浅灰色',
    color_skyblue: '天蓝色',
    color_lightblue: '浅蓝色',
    color_azure: '蔚蓝色',
    color_lightcyan: '浅青色',
    color_honeydew: '蜜瓜色',
    color_palegreen: '灰绿色',
    color_lightgreen: '浅绿色',
    color_pomonagreen: '嫩绿色',
    color_lightyellow: '浅黄色',
    color_lavender: '浅紫色',
    color_lightpink: '浅粉色',
    color_gold: '金色',
    color_yellow: '黄色',
    color_tomato: '西红柿色',
    color_beige: '米色',
    color_mistyrose: '浅玫瑰色',

    color_red: '红色',
    color_orange: '橙色',
    color_green: '绿色',
    color_blue: '蓝色',
    color_purple: '紫色',
    color_pink: '粉色',
    color_brown: '褐色',
    color_black: '黑色',
    color_white: '白色',
    color_gray: '灰色',

    add_view: '添加视图',
    view_name_placeholder: '输入视图名称',
    table: '表格',
    board: '看板',
    list: '列表',
    gallery: '画廊',
    timeline: '时间',
    chart: '统计图表',
    create_view: '创建视图',
    delete_view: '删除视图',
    default_status_name: '状态',
    groups_shown: '显示的分组',
    groups_hidden: '隐藏的分组',
    newpage: '新页面',
    new_row: '新增',
    new_card: '新卡片',

    row_item_label: '数据条目数',

    type: '类型',
    line: '线状图',
    bar: '柱状图',
    pie: '饼图',

    chart_axis: 'X轴',
    chart_datasets: '数据集',

    aggregate_to: '聚合到',
    aggregate_to_individual: '各选项',

    month: '月',
    week: '周',
    day: '日',
    quarter: '季度',
    year: '年',

    add_board_lane: '增加分组',
    groupby_setting: '分组配置',
    groupby_setting_title: '当前视图分组配置',
    groupby: '分组条件',
    delete_groupby: '删除分组条件',
    add_option_group: '添加分组',

    properties_setting: '字段配置',
    properties_setting_title: '当前视图字段配置',
    all_properties: '所有字段',

    account_not_activated: '您的账户尚未激活，激活邮件已经发送至您的邮箱: {email}，请查收并点击邮件中的链接激活账户。',
    no_activation_email: '如果您没有收到激活邮件，可以点击右侧按钮重新发送激活邮件',
    still_no_activation_email: '如果您一直收不到激活邮件，可以点击右侧按钮尝试其他注册方式',
    resend_activation_email: '重新发送激活邮件',
    try_another_way: '尝试其他注册方式',
    already_activated: '如果您已经激活，请点击按钮',

    operation: '操作',
    delete: '删除',
    done: '完成',
    nothing_in_trashbin: '垃圾箱没有内容',
    delete_all: '清空垃圾箱',
    restore: '恢复',
    confirm_title: '请确认',
    confirm_delete_content: '确认删除吗？',
    confirm_delete_doc: '确认删除吗？从垃圾箱中删除的内容将不可恢复。',
    confirm: '确认',
    cancel: '取消',
    release_to_delete: '在回收区域释放即可删除',
    remove: '移除',
    title_input: '请在上方输入页面标题/主题，以便AI进行撰写',

    drag: '拖拽',
    to_move: '可移动位置',
    click: '点击',
    to_open_menu: '可打开菜单',

    member: '成员',
    member_tooltip: '在这里可以管理空间成员，邀请、删除、授权等',
    group: '小组',
    group_tooltip: '在这里管理空间小组，可以创建小组，管理组成员等',
    guest: '访客',
    guest_tooltip: '在这里管理空间访客，访客只可访问分享给他们的页面',
    user: '用户',
    role: '角色',
    admin: '管理员',
    invite: '邀请',
    add_user: '添加成员',
    invite_user: '邀请队员',
    add_group: '添加群组',
    leave_team: '离开团队',
    remove_from_team: '从团队中移出',
    remove_self_from_org: '确定要退出当前工作空间吗？退出后如果需要重新加入，需要联系管理员。',
    remove_last_one_from_org: '您是当前工作空间唯一成员，确定要退出吗？退出本空间将无法再进入。',
    change_role_to_member: '确定要将角色从管理员更改为成员吗？',
    last_admin_in_org: '您是当前工作空间唯一管理员，无法将自己设置为成员。',
    size: '人数',
    workspace_name: '空间名称',

    to_read: '等待阅读',
    read: '已读',
    note: '笔记',
    copy: '复制',
    copy_url: '复制链接',

    new_document_private: '新建私有页面/数据表/幻灯片',
    new_document_workspace: '新建团队页面/数据表/幻灯片',
    add_ril_tooltip: '添加从第三方App或网站复制的链接',
    ril_tooltip: '从第三方App或网站保存的文章',

    doc_not_found: '页面不存在，可能已被永久删除',
    doc_in_trashbin: '页面在垃圾箱中，需要恢复吗？',

    no_access: '没有权限',
    no_access_notice: '您没有访问该页面的权限',
    no_access_guide: '请联系本页面管理员授权',

    invited_to_teams: '您被如下团队邀请加入：',
    create_or_enter_space: '创建或进入空间',
    create_or_join_space: '加入或创建空间',
    enter_space: '进入FunBlocks空间',
    create_space: '创建新空间',
    create_space_title: '您准备将方块(FunBlocks)用于：',
    personal: '个人',
    team: '团队',
    personal_desc: '非凡的写作体验，更清晰地思考，结构化的笔记和知识整理，甚至制作自讲解幻灯片和视频',
    team_desc: '文档协作，项目管理，知识积累(更强大、更好用的wiki)，视频创作，制作课件及培训管理系统',
    create_team_workspace: '创建团队工作空间',
    create_team_workspace_detail: '填写团队信息，以方便队员识别',
    team_name: '团队或组织名称',
    select_person: '选择用户',

    accept: '接受',
    reject: '拒绝',

    propertyPrefix: '字段',

    color: '颜色',
    text_color: '文字颜色',
    bg_color: '背景颜色',
    highlight_color: '高亮颜色',
    default: '默认',
    background: '背景',

    cmd_clear_marks: '转为文本',
    turn_into: '转为',
    paragraph: '文本',

    basic_blocks: '基础块',
    advanced_blocks: '高阶块',
    media: '媒介',
    inline: '行内嵌入',

    cmd_trigger: '是我想要的',
    cmd_ai_optimize: '润色',
    cmd_ai_continue: '续写',
    cmd_ai_assistant: '智能助手',
    cmd_h1: '大标题',
    cmd_h2: '中标题',
    cmd_h3: '小标题',
    cmd_blockquote: '引述文字',
    cmd_new_document: '页面',
    cmd_link_to_page: '链接到页面',
    cmd_new_xslides: '幻灯片子页面',
    cmd_new_xslides_inline: '内嵌幻灯片',
    cmd_new_flow: 'Flow子页面',
    cmd_new_db: '数据库子页面',
    cmd_new_db_inline: '内嵌数据库',
    cmd_new_db_view_table: '表格视图',
    cmd_new_db_view_board: '看板视图',
    cmd_new_db_view_list: '列表视图',
    cmd_new_db_view_timeline: '时间轴视图',
    cmd_new_db_view_gallery: '画廊视图',

    cmd_image: '图片',
    cmd_image_link: '图片链接',
    cmd_media_embed: '媒体',
    cmd_link: '链接',
    cmd_codeblock: '代码块',
    cmd_codeline: '代码行',
    cmd_mathblock: '数学公式块',
    cmd_mathline: '行内数学符号',
    cmd_hr: '水平线',
    cmd_line_break: '换行/软回车',
    cmd_block_below: '在下方插入块',
    cmd_block_above: '在上方插入块',
    cmd_highlight: '高亮',
    cmd_superscript: '上标',
    cmd_subscript: '下标',
    cmd_indent: '缩进',
    cmd_outdent: '减少缩进',
    cmd_bulleted_list: '无序列表',
    cmd_ordered_list: '有序列表',
    cmd_todo_list: '待办列表',
    cmd_align_left: '左对齐',
    cmd_align_center: '居中',
    cmd_align_right: '右对齐',
    cmd_align_justify: '两端对齐',
    cmd_table: '表格',
    cmd_table_row: '插入表格行',
    cmd_table_column: '插入表格列',
    cmd_table_delete_row: '删除表格行',
    cmd_table_delete_column: '删除表格列',
    cmd_table_delete: '删除表格',
    cmd_table_header: '设置为表头',
    cmd_emoji: '表情',
    cmd_hslide: '新幻灯片(横向)',
    cmd_vslide: '新幻灯片(垂直)',
    cmd_notes: '演讲者笔记',
    cmd_slidecolor: '幻灯片文字颜色',
    cmd_background: '图片设为幻灯片背景',
    cmd_fullscreen: '适配图片',
    cmd_fullscreencover: '适配图片',
    cmd_poll: '在线投票/测试',
    cmd_chart: '数据图表',
    cmd_iframe: '嵌入网页',

    cmd_trigger_desc: '输入文字',
    cmd_ai_optimize_desc: '润色',
    cmd_ai_continue_desc: '继续写...',
    cmd_ai_assistant_desc: '让智能助手修改或写作...',
    cmd_h1_desc: 'Heading 1',
    cmd_h2_desc: 'Heading 2',
    cmd_h3_desc: 'Heading 3',
    cmd_h4_desc: 'Heading 4',
    cmd_blockquote_desc: '展示引述文字',
    cmd_new_document_desc: '新建子页面',
    cmd_link_to_page_desc: '链接到已有页面',
    cmd_new_xslides_desc: '新建幻灯片子页面',
    cmd_new_flow_desc: '新建Flow子页面',
    cmd_new_xslides_inline_desc: '将幻灯片嵌入到本页面',
    cmd_new_db_desc: '新建数据库子页面',
    cmd_new_db_inline_desc: '创建新数据库并嵌入本页面',
    cmd_new_db_view_table_desc: '嵌入数据库表格视图',
    cmd_new_db_view_board_desc: '嵌入数据库看板视图',
    cmd_new_db_view_list_desc: '嵌入数据库列表视图',
    cmd_new_db_view_timeline_desc: '嵌入数据库时间轴、甘特图',
    cmd_new_db_view_gallery_desc: '嵌入数据库画廊视图',

    cmd_image_desc: '上传图片并嵌入本页面',
    cmd_image_link_desc: '链接图片',
    cmd_media_embed_desc: '嵌入视频或音频链接',
    cmd_link_desc: '插入网址链接',
    cmd_codeblock_desc: '展示代码块',
    cmd_codeline_desc: '展示代码行',
    cmd_mathblock_desc: '插入独立数学公式块',
    cmd_mathline_desc: '在行内插入数学符号',
    cmd_hr_desc: '插入水平分割线',
    cmd_line_break_desc: '仅换行，继续编辑当前块 (Shift + Enter)',
    cmd_block_below_desc: '退出当前块编辑，并在下方插入块 (Ctrl/⌘ + Enter)',
    cmd_block_above_desc: '退出当前块编辑，并在上方插入块 (Ctrl/⌘ + Shift + Enter)',
    cmd_highlight_desc: '以背景色突出显示文字',
    cmd_superscript_desc: '以上标形式显示文字',
    cmd_subscript_desc: '以下标形式显示文字',
    cmd_indent_desc: '增加缩进',
    cmd_outdent_desc: '减少缩进',
    cmd_bulleted_list_desc: '无数字序号的列表',
    cmd_ordered_list_desc: '有数字序号的列表',
    cmd_todo_list_desc: '以待办列表跟踪任务进度',
    cmd_align_left_desc: '以左对齐显示文字',
    cmd_align_center_desc: '以居中显示文字',
    cmd_align_right_desc: '以右对齐显示文字',
    cmd_align_justify_desc: '以两端对齐显示文字',
    cmd_table_desc: '插入普通表格',
    cmd_table_row_desc: '为表格插入新行，插入到下一行',
    cmd_table_column_desc: '为表格插入新列，插入到下一列',
    cmd_table_delete_row_desc: '删除表格当前行',
    cmd_table_delete_column_desc: '删除表格当前列',
    cmd_table_delete_desc: '删除本表格以及其中的数据',
    cmd_table_header_desc: '设置当前行为表格标题行',
    cmd_emoji_desc: 'Emoji符号',
    cmd_bold_desc: '粗体',
    cmd_italic_desc: '斜体',
    cmd_copy_desc: '拷贝',
    cmd_strikethrough_desc: '删除线',
    cmd_hslide_desc: '插入新幻灯片(横向)分隔符',
    cmd_vslide_desc: '插入新幻灯片(垂直)分隔符',
    cmd_notes_desc: '添加演讲者备注',
    cmd_slidecolor_desc: '设置当前幻灯片文字颜色',
    cmd_background_desc: '将图片设置为幻灯片背景图',
    cmd_fullscreen_desc: '将图片铺满全屏',
    cmd_fullscreencover_desc: '以cover方式将图片铺满全屏',
    cmd_poll_desc: '插入在线测试/投票',
    cmd_chart_desc: '插入数据统计图表',
    cmd_iframe_desc: '将web页面嵌入幻灯片',

    missing_required_data: '请填入必填信息',
    missing_one_data: '请输入至少一项信息',

    text: '文字',
    alt_text: '说明',
    link: '链接',
    upload_file: '上传文件',
    upload_failed: '上传失败，请稍后重试',
    poll_desc: '投票/测试问题描述',
    chart_type: '结果展示为',
    no_enough_options: '选项太少，请添加选项',
    poll_responses: '已提交人数',
    poll_result: '投票结果',
    data_source: '数据源',
    csv_data: '直接输入csv格式数据',
    csv_file: '选取本地csv文件',
    csv_url: '通过网络获取csv数据',
    rows: '行数',
    columns: '列数',
    show_csv_example: '展示csv数据样例',
    data_invalid: '数据无效',
    doc_saved: '文档已保存',
    doc_save_failed: '保存文档失败，请重试',
    save_shortcut: '保存文档快捷键: Ctrl/Cmd + S',
    editor_slash_hint: `输入'/'显示命令菜单`,

    askAI: '选择下方 AI 任务，或告诉 AI 如何处理所选内容...',
    askAI_next: '选择下方操作选项，或告诉 AI 下一步做什么...',
    askAI_doing: '智能助手正在写 ',
    draft_placeholder: '请输入主题和具体要求(如字数、要点等)...',
    fill_in_selected: '填入选中的内容',
    sendAI: '发送给AI',
    submitAI: '确认',
    reset_input: '重置',
    draft_artile: '写一篇',
    topic_label: '主题为',
    outline_prompt: '你要写一篇{article}，主题为：```{topic}```，要点：```{key_points}```。请先拟出一份清晰的提纲',
    draft_more_type: '文章类型',

    selection_text: '选中的文本:',
    ai_response: '智能助手:',

    copy_generated_content: '复制生成的内容',
    replace_selection: '接受',
    replace_title: '替换标题',
    replace: '替换',
    insert: '插入',
    insert_title: '插入标题',
    insert_below: '在下方插入',
    continue_writing: '继续写...',
    make_longer: '写长一点',
    try_again: '重试一次',
    discard: '丢弃',
    confirm_close_ai: '确定要关闭智能助手？未保存的生成内容将被丢弃。',
    confirm_discard_ai_content: '确定要返回上一步？未保存的生成内容将被丢弃。',
    confirm_no_wait: 'AI助手正在努力中，确定不再稍等一会儿吗？',
    text_too_long: '选中的文本太长',
    no_text: '没有提供给智能助手的内容',
    ai_timeout: 'AI助手太长时间没有响应',
    should_select_item: '请从下方菜单中选择下一步操作',
    should_text_or_select_item: '请从下方菜单中选择下一步操作或输入指令给AI',
    ai_response_warning: '智能助手返回的结果可能并不准确，在使用前请判断其正确性',

    bold: '粗体',
    italic: '斜体',
    underline: '下划线',
    strikethrough: '中划线',
    comment: '评注',
    keyboard: '键盘按键样式',
    align: '对齐',

    cmd_trigger_search: ' ',
    cmd_ai_continue_search: 'aic',
    cmd_ai_assistant_search: 'ai',
    cmd_h1_search: 'h1',
    cmd_h2_search: 'h2',
    cmd_h3_search: 'h3',
    cmd_blockquote_search: 'yswz',
    cmd_new_document_search: 'ym',
    cmd_link_to_page_search: 'ljym',
    cmd_new_xslides_search: 'xjhdp',
    cmd_new_xslides_inline_search: 'qrhdp',
    cmd_new_flow_search: 'xjflow',
    cmd_new_db_search: 'sjb',
    cmd_new_db_inline_search: 'qrsjb',
    cmd_new_db_view_table_search: 'bgst',
    cmd_new_db_view_board_search: 'kbst',
    cmd_new_db_view_list_search: 'lbst',
    cmd_new_db_view_timeline_search: 'sjzst',
    cmd_new_db_view_gallery_search: 'hlst',
    cmd_image_search: 'tp',
    cmd_image_link_search: 'tplj',
    cmd_media_embed_search: 'mt',
    cmd_link_search: 'lj',
    cmd_codeblock_search: 'dmk',
    cmd_codeline_search: 'dmh',
    cmd_mathblock_search: 'sxk',
    cmd_mathline_search: 'sxfh',
    cmd_hr_search: 'spx',
    cmd_line_break_search: 'hhrhc',
    cmd_block_below_search: 'xkxyh',
    cmd_block_above_search: 'xksyh',
    cmd_highlight_search: 'gl',
    cmd_superscript_search: 'sb',
    cmd_subscript_search: 'xb',
    cmd_indent_search: 'sj',
    cmd_outdent_search: 'qxsj',
    cmd_bulleted_list_search: 'wxlb',
    cmd_ordered_list_search: 'yxlb',
    cmd_todo_list_search: 'rwlb',
    cmd_align_left_search: 'zdq',
    cmd_align_center_search: 'zhdq',
    cmd_align_right_search: 'ydq',
    cmd_align_justify_search: 'lddq',
    cmd_table_search: 'bg',
    cmd_table_row_search: 'bgh',
    cmd_table_column_search: 'bgl',
    cmd_table_delete_row_search: 'scbgh',
    cmd_table_delete_column_search: 'scbgl',
    cmd_table_header_search: 'szbt',
    cmd_emoji_search: 'bqtb',

    editor_placeholder_title: '请输入标题',
    editor_placeholder_p: `键入文字或'/'选择`,

    create_new_db_view: '新建数据库视图',
    create_new_slides: '新建幻灯片',
    choose_existing_slides: '选择已有幻灯片',
    page_chooser_title: '选择',
    choose_page_to_link: '选择链接到的页面',
    editor_new_page_desc: '回车创建新空白页面，或选择如下类型页面',
    data_editor_new_page_desc: '鼠标点击这里即可创建新空白页面',
    empty_page: '空白页面',
    empty_page_with_ai: `让AI写作助手撰写 "{title}"`,
    empty_page_ai_prompt: `写一篇关于如下主题的文章：{topic}`,
    empty_slides_with_ai: `让AI写作助手撰写幻灯片 "{title}"`,
    empty_slides_ai_prompt: `写一篇关于如下主题的幻灯片：{topic}`,
    db_section_title: '数据表',
    slides_section_title: '幻灯片',
    flow_section_title: 'AI 白板 + 思维导图',

    slide_seminar_tooltip: '线上研讨会，远程同步互动，支持主讲人粉笔、白板讲解，提问互动等',
    slide_present_tooltip: '观看/播放幻灯片',
    slide_edit_tooltip: '编辑幻灯片',
    slides_tutor_tooltip: '幻灯片编辑指导',

    sorry_for_uninstall: '好遗憾您卸载了我们',
    please_tell_your_uninstall_reason: '为了让我们做得更好，请告诉我们您卸载此扩展程序的原因',
    thanks_for_feedback: '感谢您的反馈，我们会认真改进。希望您能再次使用我们的扩展程序！',
    please_give_reason: '请告诉我们您卸载此扩展程序的主要原因',
    please_give_detail: '请给我们留下您宝贵的建议和信息',
    submit: '提交',

    install_funblocks_ai_extension: '安装FunBlocks AI浏览器插件，随时召唤AI助手，提高工作和学习效率。',
    intro_extension: 'FunBlocks AI浏览器插件利用最先进的AI模型（GPT-4、Gemini、Claude等），帮您做到"10倍速、高质量"的：阅读、写作、社交互动、邮件摘要和回复、视频内容摘要等',
    intro_funblocks: '同时，FunBlocks不仅提供浏览器插件工具，还是一款比Notion更彻底的一站式AI平台。以编辑器为界面，让您与AI助手协同工作，从而提升写作和学习效率。来体验一下吧：',
    copy_invite_link: '复制邀请链接',
    invitation_records: '邀请记录',
    click_to_copy_msg_link: '点击复制邀请信息和链接',
    download_extension_to_participate: '免费安装插件',
    welcome_to_funblocks: '欢迎来到 FunBlocks AI',
    invite_to_earn: `邀请有奖励！
    每邀请一个好友，获得 {coins} AI 币
    `,
    invited_to_given: '被邀请用户也将获赠 {coins} AI 币',
    free_coins_awarded: '现在下载，即可额外获得 {coins} 次免费 AI 访问',
    detail: '详细',
    invited_frends: '邀请的好友数',
    total_rewards: '奖励的 AI币',
    friend: '好友',
    reward: '奖励',
    time: '时间',

    flow_ask_placeholder: '随便问...',
    flow_ask_question_placeholder: '提问...',
    flow_ask_question_or_choose_skill_placeholder: '提问或选择AI技能...',
    flow_dynamic_placeholder: '请输入您的需求...',
    flow_dynamic_with_menu_placeholder: '请输入需求，或选择下方应用...',
    flow_breakdown_placeholder: '输入任何话题来拆解...',
    flow_brainstorming_placeholder: '输入你的主题开始头脑风暴...',
    flow_link_placeholder: '输入网页链接，探索网页内容...',
    flow_search_placeholder: '让 AI 进行网络搜索...',
    flow_perspective_placeholder: '输入新的思路...',
    flow_idea_placeholder: '输入新想法...',
    flow_task_analysis_placeholder: '任务分析...',
    flow_new_task_placeholder: '输入任务描述...',
    flow_todos_placeholder: '输入你的任务或项目',
    flow_new_sub_topic_placeholder: '输入新子主题...',
    flow_new_insight_placeholder: '输入新见解...',
    flow_mindmap_primary_branch_placeholder: '输入关键概念/要点...',
    flow_add_improve_plan_placeholder: '输入新改进项目...',
    new_perspective: '新角度',
    new_idea: '新想法',
    new_task: '新任务',
    remove_task: '删除任务',
    new_sub_topic: '增加子主题',
    new_insight: '新见解',
    mindmap: '思维导图',
    mindmap_primary_branch: '探索给定文本中的关键概念/要点',
    generate_mindmap: '生成思维导图',
    summarize_tips: '生成总结',
    summarize: '总结',
    summary: '总结',
    more_ai_menu: '更多AI功能',

    click_to_execute: '点击让AI{action}',
    node_action_dynamic: '执行指令',
    node_action_ask: '回答问题',
    node_action_brainstorming: '头脑风暴',
    node_action_breakdown: '拆解',
    node_action_todos: '拆解任务',
    node_action_search: '搜索网络',
    node_action_link: '总结网页内容',
    node_action_dynamic_with_menu: '执行指令',
    node_action_ask_question: '回答问题',

    toggle_options: '显示可选项',
    change_prompt_querytype: '切换 AI 模式',

    describe_image: '以智者之言描述图片内容',
    breakdown_topics: '主题拆解',
    breakdown_topics_tips: '拆解复杂主题到子主题',
    ask_question: '问个问题',
    ask_question_tips: '问个问题，AI帮你优化问题',
    dynamic: '做个任务',
    dynamic_tips: '提出需求，AI会和你互动细节',
    brainstorming: '头脑风暴',
    brainstorming_tips: 'AI帮你头脑风暴，以思维导图展现',
    todo_list: '待办清单',
    generate_todo_list: '任务分解',
    generate_todo_list_tips: '任务分解，生成待办清单',
    empty_todo_list: '创建一个新的待办清单',
    empty_todo_list_tips: '点击下面的按钮添加您的第一个待办事项',
    search_web: '从网络搜索最新信息',
    explain: '解释',
    selectable_context: '上下文',
    add_note: '便签',
    add_image: '插入图片',
    add_link: '插入网页链接',
    add_caption: '添加说明文字',
    to_note: '复制到可编辑便签',
    as_note: '转换成便签',
    copy_node: '拷贝节点',
    save_to_workspace: '保存到工作空间，方便后续编辑',
    export_to_pdf: '导出为PDF',
    to_todolist: '复制到待办清单',
    paste_as_node: '粘贴',
    type_note: '输入备忘文本',
    group_nodes: '组合选中的节点',
    ungroup_nodes: '取消组合',
    remove_from_group: '移出组合',
    redo: '重做',
    undo: '撤销',
    export_to_image: '导出为图片。分享给朋友可获得额外免费的AI访问额度',
    add_private_llm_key: '添加私有LLM API Key',
    get_models_test_token: '获取模型列表/测试 API Key',
    token_valid: 'Key 和 端口 已验证',
    token_invalid: '请检查 Key 或 端口 是否正确',
    set_ai_model: '设置 AI 模型',
    set_default_ai_model: '设置默认 AI 模型',
    set_lang_as_context: '根据上下文语言',
    set_ai_response_lang: '设置 AI 写作语言',
    tell_me_more: '进一步探索...',
    selectable_ideas: '可选想法',
    hide_action_box: '隐藏',
    get_more: '换一批',
    remove_node: '删除节点',
    expand: '展开',
    collapse: '收起',
    search_results: '搜索引擎结果',
    query_analysis: '对原始问题的分析',
    optimized_query: '优化的问题',
    show_detail: '展示详情',
    hide_detail: '隐藏详情',

    new_board: '新建白板',
    edit_title_placeholder: '修改标题',
    please_set_ai: '请登录 AI Flow 或设置第三方语言模型服务提供商',
    please_set_api: '你选择了第三方LLM服务提供商，但API参数未正确设置',
    exceed_msg_limit: '您已达到每日 {model_level} 模型访问限额。请升级您的FunBlocks AI计划或选择其他级别模型继续使用。',
    no_aigc_content: 'AI未能生成内容。',
    copy_to_edit: '复制为可编辑副本',
    flow_info_login: '请先登录',
    flow_info_extension_not_supported_for_current_browser: '请使用Chrome或Edge浏览器下载安装FunBlock AI扩展。',
    flow_info_copy_to_editable: '当前白板为只读模式。如需修改，请复制一份可编辑版本。',
    flow_info_saved_to_workspace: '内容已保存到工作空间，点击下方按钮即可继续编辑。',  
    to_workspace: '继续编辑',
    copy_to_editable: '复制',
    flow_settings: '设置',
    settings_funblocks_desc:
      '注册 FunBlock 账号后，您将获得 40 次 AI 助手服务的试用次数，并每天享有 20 次免费的 AI 助手服务。此外，您还可选择升级至 FunBlock AI 会员计划，以获取无限次的 AI 助手访问。',
    invalid_api_for_image: 'AI 助手暂不支持向第三方LLM服务询问图片信息，请选择 FunBlocks AI 服务',
    ai_insights: '生成探索空间',
    summuary_insights: '见解总结',

    cut: '剪切',
    paste: '粘贴',
    paste_as_plain_text: '纯文本粘贴',

    funblocks_aiflow: 'FunBlocks AIFlow',
    aiflow_slogan: '与 AI 共创，探索无限可能',
    qr_code_tips: '扫描二维码继续探索...',
    share_tip: '分享你与AI的精彩探索给朋友，还可获得免费的AI访问额度。',
    ai_token: 'AI Token',
    ai_token_desc: '标准 AI请求花费 1个 AI币，高级 AI请求花费 5个 AI币。',
    your_ai_token_balance: '你的 AI币 余额: ',
    ai_tokens_income: 'AI币 收入记录',
    show_latest_records: '只显示最近 {count} 条',
    wanna_more_ai_tokens: '获取更多 AI 币：',
    date: '日期',
    count: '数量',
    income_item: '收入项目',
    new_user_trial: '新用户奖励',
    invited_by_friend: '被邀请奖励',
    invite_friend: '邀请用户奖励',
    shared_aigc_visited: '分享AI作品被阅读奖励',
    share_aigc: '分享 AI Flow 作品给朋友或社区',
    share_aigc_tips: '每个人通过你的分享链接阅读作品时，你都将获得 10 个 AI Token 作为奖励。你可以通过点击分享按钮分享链接，或将作品导出为图片再分享。',
    choose_flow_board: '创建或选择 Flow 页面',
    priority: '优先级',
    due_date: '截止日期',
    breakdown_task: '将任务拆解为子任务',
    copy_content: '复制内容到剪切板',
    save_page: '保存',

    advanced_settings: '更多设置',
    type_or_select_placeholder: '输入或从右侧菜单选择...',

    topic_type: '应用场景',
    thematic_learning: "主题学习",
    personal_growth: "个人成长",
    startup_idea: "创业点",
    bussiness_analysis: "商业分析",
    investment_analysis: "投资分析",
    product_innovation: "产品创新",
    solutions_design: "解决方案设计",
    project_planning: "项目规划",
    marketing_strategies: "营销策略",
    branding_building: "品牌建设",
    advertisiing_compaign_ideas: "广告活动创意",
    root_cause_analysis: "根本原因分析",
    team_management: "团队管理",
    operation_optimization: "运营优化",
    innovative_ideas: "创新理念",
    event_planning: "活动策划",
    decision_analysis: "决策分析",
    meeting_disucssions: "会议讨论",
    travel_experiences: "旅游体验",
    writing_ideas: "设计写作框架",
    research_topics: "研究课题",
    curriculum_design: "课程设计",
    social_issue_solutions: "社会问题解决方案",


    thinking_model: '思维模型',
    swot_analysis: "SWOT分析",
    business_model_canvas: "商业模式画布",
    fivew1h_method: "5W1H方法",
    scamper_method: "SCAMPER方法",
    six_thinking_hats: "六顶思考帽",
    pdca: "PDCA循环",
    systems_thinking: "系统思考",
    steep_analysis: "STEEP分析",
    five_forces: "五力模型分析",
    four_p: "4P营销组合",
    triz: "发明问题解决理论",
    rephrazing: "问题重新表述",
    learning_pyramid: "学习金字塔",
    kwl: "KWL图",
    changing_perspectives: "改变视角",
    reverse_thinking: "反向思维",
    role_playing: "角色扮演",
    mckinsey_7S_framework: "麦肯锡7S模型",
    value_proposition_design: "价值主张设计",

    payment: "购买",
    flow_exported_image_watermark_qrcode: "在下载的AI Flow图片中附带二维码",
    flow_exported_image_watermark_qrcode_tips: "二维码包含邀请码，用户通过该链接访问或注册，您将获得相应的AI Token奖励",

    selected_node: '父节点内容',
    selected_node_and_descendants: "父节点及其子孙节点内容",
    grouped_nodes: '组合中的节点',
    reflect_and_improve: '反思与改进',
    reflect: '反思',
    improvement_plan: '改进方案',
    improve_now: '改进',
    add_improvement_plan: '增加项目',
    failed_load_image: '未能获取图片数据',

    more_info_form: '请提供更多信息',
    generate_content_format: '生成内容格式',
    ai_infered_task_type_description: '根据任务描述，AI会推断任务类型，后续的信息也会依赖于这个推断。如果AI的推断有误，请重新选择任务类型，并点击右侧按钮重新调用AI助手。',
    underlying_needs: '需求或目标',
    softbreak_tips: "使用 'Shift + Enter' 进行换行",
    paste_to_board_hint: '支持直接粘贴文本、链接、图片到白板',
    "keyboard_save_hint": "Control/Command + s: 保存白板",

    'flow_settings_mouse_touch': '鼠标和触控设置',
    'flow_settings_drag_behavior': '拖拽行为',
    'flow_settings_drag_pan': '拖拽 = 平移画布',
    'flow_settings_drag_select': '拖拽 = 选择白板区域',
    'flow_settings_scroll_behavior': '滚动行为',
    'flow_settings_scroll_zoom': '滚动 = 缩放画布',
    'flow_settings_scroll_pan': '滚动 = 平移画布',
    'flow_settings_pinch_zoom': '启用捏合缩放',
    'flow_settings_key_mouse_combo': '键盘和鼠标组合操作',
    'flow_settings_selection_key': '选择操作键',
    'flow_settings_selection_hint': '选择白板区域: {key} + 拖拽',
    'flow_settings_pan_key': '平移操作键',
    'flow_settings_pan_hint': '平移画布: {key} + 拖拽',
    'flow_settings_zoom_key': '缩放操作键',
    'flow_settings_zoom_hint': '缩放画布: {key} + 滚动',
    'flow_settings_delete_node': '启用 Backspace 删除节点',
    'flow_settings_scroll_pan_horizontal_hint': 'Shift + 滚轮: 水平移动',
    'pinch_zoom_tip': '捏拉 = 缩放',

    "flow-editor": "欢迎使用 FunBlocks AIFlow！",
    "flow-boards": "查看或创建白板",
    "dynamic-ai-nodes": "开始AI互动",
    "more-ai-nodes": "更多AI互动节点",
    "non-ai-nodes": "便签、待办清单",
    "model-selector": "选择AI模型",
    "output-lng-selector": "设置AI输出语言",
    "board-operations": "保存、撤销、重做",
    "share-board": "导出或分享白板，获得免费AI访问奖励",
    "download-extension": "下载FunBlocks AI浏览器插件，提升工作、学习效率",
    "settings": "应用设置，账户管理",
    "board-viewport-operations": "调整白板视图",
    "board-interaction-hint": "白板操作指南",
    "minimap": "缩略图导航",
    "temp_notesbook": "剪贴簿",
    to_temp_notebook: "添加到剪贴簿",
    merge_notes: '合并笔记',
    select_all: '全选',
    deselect_all: '取消全选',
    delete_selected_clips: '删除选中的剪报',
    regenerate_tooltip: '将使用选定的模型生成一个新节点',
    generated_with: '内容由以下模型生成',
    regenerate_with: '可以另选模型重新生成',
    save_to_prompt: '保存 Prompt',
    space_intro: 'FunBlocks 工作空间',
    to_workspace: '进入工作空间',
    what_is_workspace: 'FunBlocks 工作空间是什么？',
    workspace_intro: `FunBlocks Workspace 是一个一体化的知识工作空间，您可以在其中创建、组织文档、演示文稿和思维导图，并进行协作。 在人工智能助手的支持下，工作区会学习并适应您的需求，成为您的个性化知识库，从而提高您的工作效率并简化创意工作流程。`,
    generate_slides: '生成幻灯片',
    node_export: '导出',
    share_prompt_title: '分享一个精彩的 FunBlocks AI Prompt应用：{prompt}。打开链接，点击"运行"，让 AI 助手为你展现魔力！',
    add_promo_code: '添加优惠码',
    enter_promo_code: '优惠码',
    apply: '使用',
    layout_boards: '节点自动布局',
    optimize_question: '优化问题',
    optimize_search: '优化搜索关键词',
    to_original_page: '打开原页面',
    add_funblocks_page: '添加 FunBlocks 页面(文档或幻灯片)',
    page_chooser_title: '选择一个 FunBlocks 页面(文档或幻灯片)',
    receive_update_emails: '接收应用更新邮件',
    receive_update_emails_tips: '您将收到有关新功能和重要更新的电子邮件。',
    flow_info_action_requires_specific_models: '此操作需要特定模型: {models}。请切换到相应模型。',
    infograph: '信息图',
    infograph_tips: '生成流程图、幻灯片、或信息图等',
    all_hints: '所有提示',
    show_all_hints: '显示所有提示',
    'flow_settings_show_examples': '在输入节点上方显示样例',
    'flow_settings_show_hint_panel': '在白板底部显示交互提示',
    choose_image: '选择图片',
    paste_image_url: '粘贴图片链接',
    or: '或',
    zoom_in: '放大',
    zoom_out: '缩小 ',
    fullscreen: '全屏'
  },
  en: {
    untitled: 'Untitled',
    title: 'Title',
    sidebarTitle: 'FunBlocks',
    team_space: `'s Team Space`,
    personal_space: `'s Personal Space`,
    workspace: 'WorkSpace',
    workspace_tooltip: 'Team members can access these pages',
    private: 'Private',
    private_tooltip: 'Only you can access these pages',
    shared: 'Shared',
    shared_tooltip: 'Only people you shared can access these pages',
    ril: 'Read Later',
    share: 'Share',
    back: 'Back',
    close: 'Close',
    cancel: 'Cancel',
    confirm: 'Confirm',
    confirm_done: 'Done',
    open: 'Open',
    create: 'Create',
    loadmore: 'Load More',
    new_page: 'New Page',
    refresh: 'refresh',

    get_title: 'Get title',

    more_menu: 'Click to have more actions',
    export: 'Export',
    export_formats_doc: 'PDF, Word, Markdown, Html',
    export_formats_db: 'CSV',
    export_formats_slides: 'PDF, Markdown',
    export_format: 'Export format',
    export_no_data: 'No data to export',
    export_slides_notes: 'Speaker notes',
    excluded: 'excluded',
    separated: 'separated page',
    same_page: 'same page',

    import: 'Import',
    import_settings: 'Settings: ',
    csv_header_confirm: 'Use the csv header as the database header',
    csv_column: 'Column {num}',
    csv_first_line: 'First line in the CSV file: ',

    error_msg: 'Something went wrong!',

    invite_code: 'Invite Code',
    invite_friends: 'Invite Friends',
    invite_friends_desc: 'reward AI tokens',
    or_invite_friend_rewards: 'or get rewards through',
    copy_intruction: 'Copy following text and send to friends:',
    homepage: 'Home page',
    feedback: 'Feedback',
    reply: 'Reply',
    notice: 'Notice',
    new_version_available: 'New version available, click button below to update',
    upgrade_now: 'Update now',
    upgrade_plan: 'Upgrade Now',
    err_catched: 'An app error catched, click button below to reload',
    reload: 'Reload',

    new_tag: 'New Tag',

    slides: 'Slides',
    db: 'Data Source',
    doc: 'Page',
    flow: 'AI Flow',
    ai_flow: 'AI Whiteboard + Mindmap',
    ai_slides: 'AI Slides',
    ai_infographics: 'AI Graphics',

    notag_label: 'No {label}',

    share: 'Share',
    edit: 'Edit',
    comment: 'Comment',
    full_access: 'Full Access',
    full_access_description: 'Can edit and share with others',
    can_edit: 'Can Edit',
    can_edit_description: 'Can edit, but not share with others',
    can_comment: 'Can Comment',
    can_comment_description: 'Can comment, but not edit',
    can_view: 'Can View',
    can_view_description: 'Cannot edit or share',

    open_to_public: 'Open to Public',
    everyone_at_org: 'Everyone at Space',
    everyone_at_group: 'Everyone at Group',

    copy_link: 'Copy Link',
    duplicate: 'Duplicate',
    copied: 'Copied to clipboard',
    copy_failed: 'Failed to copy',

    history: 'Editing History',
    restore_doc_tip: 'Restore to this version? ',
    restore_doc_tooltip: 'This will overwrite the current content',
    no_history_doc_selected: 'No history version selected',
    doc_history: 'Page Editting History',

    doc_play: 'Convert to Slides',
    slides_seminar: 'Seminar mode',
    slides_show: 'Slides mode',
    slides_editor: 'Make Video',
    slides_ai_converter: 'Make Slide with AI',

    trashbin: 'Trash Bin',
    trashbin_tooltip: 'Drag pages here to delete them',
    ril: 'Read It Later',
    settings: 'Members and settings',
    settings_account: 'Account Settings',
    settings_workspace: 'Workspace Settings',

    settings_my_account_menu: 'My Account',
    settings_my_account_title: 'My Account',
    settings_ril_menu: 'Instant Notes',
    settings_ril_title: 'Read It Later Plugin Settings',
    settings_ril_workspace_selection: 'Select a workspace for instant notes from FunBlocks mobile App ',
    settings_flow_title: 'Flow Interaction Settings',
    settings_flow_menu: 'Flow Interaction',
    settings_more_menu: 'More settings',
    settings_more_title: 'More Settings',
    settings_language_desc: 'Select your UI language',
    settings_api_menu: 'LLM Service Providers',
    settings_api_title: 'Large language model(LLM) API Settings',
    settings_api_enable_api: 'Enable LLM API access',
    settings_api_llms: 'Providers',
    self_owned_ai_api: 'Private LLM API Keys',
    llm_api_key: 'API Key',
    llm_api_token_guide: 'You can login to {aiProvider} to get API Key',
    settings_api_choose_model: 'Choose a model',
    settings_api_proxy: 'Endpoint',
    openai_compatible:
      '### When using an LLM service compatible with the OpenAI interface, simply follow these steps:\n1. Enter the interface address in the "Endpoint" field;\n2. Choose the "Other" from "models" and provide the model name.',
    other: 'Other',
    settings_api_desc: '### After enabling and setting 3rd party LLM API key: \n1. FunBlocks AI will directly access the LLM with the API Key you provided, without the limitation of the number of request to FunBlocks AI. \n2. The API key is saved locally and will not be uploaded to the FunBlocks server, so there is no need to worry about being stolen.',
    invalid_api_settings: 'It appears you have initiated integration with a 3rd party LLM service but have yet to finalize the requisite configurations. Please re-examine the settings to ensure proper completion.',
    has_ai_api: 'Has LLM API key?',
    settings_prompts_menu: 'AI Apps/Prompts',
    settings_prompts_title: 'AI Assistant Apps',
    add_prompt: 'Create App',
    task_content: 'Variables input',
    task_content_from_selected: 'Selected text from page',
    task_content_from_input: 'Text input',
    task_content_from_form: 'Custom form fields',
    advanced_options: 'Advanced options',
    context_usage: "Use/Process Context",
    context_usage_desc: "When the AI assistant executes a prompt, it will automatically choose and use the context based on the application scenario. You can also specify how to use the context in the prompt by including '{{selected_text}}'. For example: 'Given the received message or comment content: {{selected_text}}.\nPlease read the above content, understand the sender's intent, and write a reply that addresses the sender's intent and other requirements.'",    
    task_content_form: "Custom Prompt Input Fields",
    task_content_form_desc: "When executing a prompt, the AI needs to understand the user's specific requirements. Breaking these requirements into multiple input fields helps users understand and input their needs, and allows the AI to understand them more accurately, resulting in more satisfactory outcomes.",
    ask_ai_about_selected: 'Given text',
    ask_ai_about_selected_tooltip: 'Ask AI with given text as context',
    ask_ai_about_given_text_hint: 'Please enter or paste the text to be processed',

    prompt_title: 'Title',
    prompt_title_placeholder: `Use a descriptive title to help users understand the prompt's function`,
    developer: 'Developer',
    description: 'Description',
    prompt_desc_placeholder: 'A description of this AI App/Prompt, for example, what it can do, etc...',
    prompt_template: 'Prompt template',
    prompt_template_desc: "The prompt for AI, which could contain {selected_text}{variables}. During AI execution, {selected_text} will be replaced with context you provide",
    prompt_template_desc_1: `When the AI assistant executes a prompt, it will automatically append the user's input to the prompt. Similar to '{{selected_text}}', you can also specify how to handle the user's input in the prompt with references to: {variables}`,
    prompt_template_placeholder: `Prompt, for example: I want you to act as a creative writing assistant. The given text is the first part of the article. Please understand the author's writing intention and continue to write the next paragraph. It is required to maintain a consistent style, coherent and logical content.`,
    prompt_template_fixed_placeholder: `Prompt, for example: Tell me a funny joke. `,
    prompt_no_text_err: 'Make sure to include {variables} in your prompt template',
    prompt_content_placeholder: 'Text for AI assistant',
    prompt_content_label: 'Topic',
    prompt_context: 'Prompt context',
    prompt_context_desc: 'Provide relevant content for the AI assistant to execute prompt',
    context_no_doc_choosen: 'Please choose a page for AI assistant as context',
    context_doc_no_content: 'The selected page has no readable content',
    context: 'Given context',
    current_doc: 'Current page',
    choose_a_doc: 'Choose a page',
    none: 'None',
    settings_space_menu: 'Settings',
    settings_space_title: 'Workspace Settings',
    settings_members_menu: 'Members',
    settings_labs_menu: 'Labs',
    settings_db_capability_switch: 'Database enabled',
    service_subscribe: 'Upgrade',
    service_subscribe_ai: 'Upgrade AI',
    service_subscribe_funblocks: 'Upgrade FunBlocks',
    service_subscribe_title: 'Upgrade plans',
    service_product_name: '{service} {level}',
    service_market: 'Buy AI Token',
    price: 'Price',
    num: 'Quantity',
    name: 'Name',
    subscribe: 'Subscribe Now',

    pin: 'Pin',
    pinned: 'Pinned',
    visibility: 'Visibile to',
    visibility_private: 'Only me (Private)',
    visibility_public: 'Everyone (Public)',
    visibility_workspace: 'Everyone (@workspace)',
    prompts_mine: "My Apps",
    prompts_mine_desc: 'You can create your own AI assistant apps here or choose from public apps that suit your needs.',
    prompts_developed: "My Apps (I've developed)",
    prompts_public: "Public Apps",
    prompts_workspace: 'Workspace Apps',
    prompts_pinned: "Pinned Apps",
    prompts_pinned_desc: "You can pin your favorite apps from public or shared apps, and the AI assistant will display your pinned apps in the menu, ready for you to execute at any time.",
    prompts_drafter: "FunBlocks AI apps",
    prompts_validate: "Validate Apps",
    prompt_qualified: "Qualified",
    prompt_not_qualified: "Not Qualified",
    prompt_no_arg: 'No variable in prompt',
    run_prompt: "Run",
    prompt_language: "App description language",
    all: 'All',
    arg_type: 'Type',
    arg_type_textline: 'Text',
    arg_type_input: 'Text',
    arg_type_text: 'Multiline text area',
    arg_type_textarea: 'Multiline text area',
    arg_type_select: 'Select',
    select_option_placeholder: 'Option for select',
    select_option_free_input: 'Allow users to enter values other than options',
    no_suitable_option: 'No suitable option',
    user_input_option: 'Input your own value',
    arg_name: 'Variable',
    arg_label: 'Title',
    arg_hint: 'Hint',
    arg_default_value: 'Default value',
    arg_required: 'Required',
    arg_optional: 'Optional',
    yes: 'Yes',
    no: 'No',
    arg_label_placeholder: 'Field Title',
    arg_hint_placeholder: 'Show user the appropriate content to fill in this field',
    arg_default_value_placeholder: 'Default value',
    add_arg: 'Add Field',
    clear_arg: 'Clear',
    update: 'Update',
    generate_outline: 'Generate outline with AI',
    no_topic: 'Please provide a topic to write about',
    prompt_blocked_safety: 'Contain dangerous or inappropriate information in task prompts',
    unknown_failure: 'Unknown error',

    prompt_app: 'FunBlocks Prompt Application',
    prompt_unpin_tooltip: 'Remove it from my AI assistant',
    prompt_pin_tooltip: 'Pin it to my AI assistant',
    prompt_clone_tooltip: 'Clone App',
    prompt_edit_tooltip: 'Edit App',
    prompt_delete_tooltip: 'Delete App',
    link_copied: 'Sharing link successfully copied to clipboard',
    prompt: 'AI Assistant Instruction',
    confirm_open_ai_generated_slides: 'The slideshow has been generated and saved in private space, continue to edit?',

    CRUD: 'Add/Update/Delete',
    not_provided: 'Not provided',
    new: 'New',
    clone: 'Clone',

    service_name_ril: 'FunNotes',
    service_name_funblocks: 'FunBlocks',
    service_name_xslides: 'Slides',
    service_name_ai: 'FunBlocks AI ',
    service_name_aiplus: 'AI',

    funblocks_product_slogan: 'Boost your productivity with FunBlocks',
    funblocks_promotion_slogan: 'We offer one of the most cost-effective solutions in the market!',
    funblocks_ai_product_slogan: 'Unleash your limitless potential with FunBlocks AI',
    funblocks_ai_promotion_slogan: 'We offer one of the most cost-effective solutions in the market!',
    funblocks_aiinsights_promotion_slogan: 'We offer the most creative AI tools in the market!',

    yearly: 'Annually',
    monthly: 'Monthly',
    cost_save: 'Save ',
    upgrade_to_vip: 'Upgrade your plan',
    purchase_ai: 'Purchase AI Premium',
    cancel_subscription: 'Cancel current plan',
    billed_period: 'Billing period',
    trial_vip: '{trial_days} days FREE trial',
    trial_desc: 'You will have a free trial of VIP plan for {trial_days} days, and there will be no charges during this period.',
    trial_confirm: 'Start Free Trial',
    wont_charge_today: "You won't be charged today",
    order_summary: 'Order Summary',
    upgrade: 'Upgrade',

    service_level_free: 'free',
    service_level_vip: 'VIP',
    service_level_plus: 'Plus',
    service_level_pro: 'Pro',
    service_level_premium: 'Premium',
    service_level_unlimited: 'Unlimited',
    service_level_smallteam: 'small team',
    service_level_enterprise: 'Enterprise',

    supports: 'Supports',
    advantage_of_product: 'Advantages',
    generate_insights_cards: 'Generate AI insights cards',
    generate_insights_cards_quota: 'Daily generate AI Graphics: {quota} times',
    more_queries_per_day: 'More queries per day',
    query_quota: '{model_level}: {quota} queries',
    unlimited: 'Unlimited',
    advanced_features: 'Advanced features',
    writing_assistant: 'AI Writing Assistant',
    reading_assistant: 'AI Reading Assistant',
    other_llm_providers_enabled: 'Use your self-owned LLM API key to query the AI assistant',
    other_llm_providers_enabled_desc: 'Supports OpenAI GPT-3.5/4, Claude-3, Gemini APIs with no daily access restrictions.',
    build_ai_apps: 'Customize AI Assistant Prompts',
    other_benefits: 'Other benefits',
    technical_support: 'Priority technical support',
    no_traffic_control: 'No request limits during high-traffic periods',
    more_capacity: 'More space',
    memos_capacity: 'Daily Memo storage capacity: {quota}',
    memos_capacity_desc: 'Supports saving of content generated by the AI assistant to Memo. Also, allows saving of selected web page content to Memo.',
    blocks_capacity: 'Monthly block storage capacity: {quota}',
    blocks_capacity_desc: 'Block is the fundamental unit that makes up the content of FunBlocks pages. It can be text, images, tables, code snippets, or any other type of content.',
    team_size: 'Maximum members in workspace: {quota}',
    markdown_slides: 'Markdown Slides Writing',
    markdown_slides_desc: 'Supports creating slides using Markdown format, simple and efficient, equipped with an AI writing assistant to boost your productivity.',
    doc_to_slides: 'Convert Document to Slides by AI',

    checkout_form_title_aiplus: 'Get more powerful FunBlocks AI',
    checkout_form_title_funblocks: 'Upgrade to {level}',
    checkout_form_title_aiinsights: 'Get more powerful AI Insights',
    checkout_total: 'Total due today',
    sub_total: 'Subtotal',

    buyable_plans: 'Funblocks Plans',
    privileges: 'Privileged features',
    privileged_feature: 'This feature is privileged to VIP plan',
    current_plan: 'Current plan',
    plan_expire_at: 'Current plan will expire at: {date}',
    last_plan_expired: 'Your last {plan} plan expired at {date}, renew it now!',
    current_plan_expire_at: 'Your {plan} plan will expire on {date}',
    buy: 'Buy now',
    paid_to_check: 'Paid, refresh status',
    subscribe_status_updated: 'Subscription status updated',
    aicoins_status_updated: 'AI tokens balance updated',
    goto_vip_buy_page: 'Upgrade to VIP',
    aicoin: 'AI Coins',
    exceeded_quota: 'You have reached the current plan usage limit.',

    choose_login_method: "Choose Login Method",
    sign_in: 'Sign in to FunBlocks',
    sign_in_with: 'Sign in with {method}',
    sign_in_with_password: 'Sign up/Sign in using password',
    sign_in_with_email_vcode: 'Sign in with Email verification code',
    google: 'Google Account',
    password_account: 'Password',
    login_signin_form_info: 'Sign In to your account',
    login_signin_vcode_form_info: 'Login or create your FunBlocks account',
    login_resetpswd_form_info: 'Reset your password with verfication code',
    login_signup_form_info: 'Register your account to FunBlocks',
    forgotpswd: 'Forgot password?',
    hadaccount: 'Already have an account?',
    login: 'Login',
    vcode_login: 'Login with verification code',
    password_login: 'Login with password',
    logout: 'Logout',
    signup: 'Sign Up',
    resetpswd: 'Reset Password',
    not_registered: 'Not registered?',
    register_now: 'Register Now',
    signup_info: `Don't have a FunBlocks account yet? You can complete the registration in one minute, and immediately experience the fun of writing with FunBlocks AI.`,
    getvcode: 'Get Code',
    verification_code: 'Verification Code',
    vcode_err: `Code incorrect, if it's unclear, you can click it to refresh`,
    phone: 'Phone',
    email: 'Email',
    phone_or_email: 'Phone or Email',
    nickname: 'Nick name',
    captcha: 'Captcha',
    password: 'Password',

    rename: 'Rename',
    insert_right: 'Insert Right',
    insert_left: 'Insert Left',
    delete_property: 'Delete Property',
    confirm_delete_property: 'Confirm to delete property: {label}? Deleted property will also delete data under this property.',
    property_type: 'Property Type',
    hide_property: 'Hide Property',
    add_property: 'Add Property',

    confirm_delete_item: 'It cannot be restored after deletion. Are you sure to delete?',

    sort: 'Sort',
    sorts: 'Sorts',
    sort_ascending: 'Sort Ascending',
    sort_descending: 'Sort Descending',
    add_sort: 'Add Sort',
    delete_sort: 'Delete Sort',
    operation_need_to_delete_sort: 'Would you like to remove sorting?',
    asc: 'Ascending',
    desc: 'Descending',

    filter: 'Filter',
    filters: 'Filters',
    add_filter: 'Add Filter',
    delete_filter: 'Delete Filter',
    delete_filter_rule: 'Delete Filter Rule',
    delete_filter_group: 'Delete Filter Group',
    filter_where: 'Where',
    filter_and: 'And',
    filter_or: 'Or',
    add_filter_rule: 'Add Filter Rule',
    add_filter_group: 'Add Filter Group',
    turn_into_filter_group: 'Turn Into Group',

    equals: 'Is',
    not_equals: 'Is Not',
    less_than: 'Less Than',
    less_than_or_equal: 'Less Than Or Equal',
    greater_than: 'Greater Than',
    greater_than_or_equal: 'Greater Than Or Equal',
    contains: 'Contains',
    not_contains: 'Not Contains',
    starts_with: 'Starts With',
    ends_with: 'Ends With',
    is_empty: 'Is Empty',
    is_not_empty: 'Is Not Empty',
    is_true: 'Is True',
    is_false: 'Is False',
    date_greater_than: 'Is After',
    date_less_than: 'Is Before',
    date_greater_than_or_equal: 'Is on or after',
    date_less_than_or_equal: 'Is on or before',
    date_between: 'Is Within',

    type_title: 'Title',
    type_text: 'Text',
    type_number: 'Number',
    type_date: 'Date',
    type_person: 'Person',
    type_select: 'Select',
    type_multi_select: 'Multi-select',
    type_checkbox: 'Checkbox',
    type_email: 'Email',
    type_phone: 'Phone',
    type_url: 'URL',

    type_created_at: 'Created time',
    type_updated_at: 'Last edited at',
    type_created_by: 'Created By',
    type_updated_by: 'Last edited by',
    type_tags: 'Tags',

    advanced_type: 'Advanced',
    basic_type: 'Basic',

    view_config: 'View',
    view_settings: 'View Settings',
    timelineby: 'Timeline property',
    use_time_pair: 'Separate start and end dates',
    timeline_start: 'Start Date',
    timeline_end: 'End Date',
    timeline_color: 'Timeline color',

    info_set_timeline_title: 'No Date property configured for timeline view',
    info_set_timeline_content: 'Please configure timeline Date property in the view settings.',

    has_end_date: 'Has End Date',
    has_time: 'Include Time',
    date_format: 'Date Format',

    clear_cell_data: 'Clear',

    date_format_ymd_dash: 'YYYY-MM-DD',
    date_format_mdy_dash: 'MM-DD-YYYY',
    date_format_dmy_dash: 'DD-MM-YYYY',
    date_format_ymd_slash: 'YYYY/MM/DD',
    date_format_mdy_slash: 'MM/DD/YYYY',
    date_format_dmy_slash: 'DD/MM/YYYY',

    view_timeline_by_day: 'Day',
    view_timeline_by_week: 'Week',
    view_timeline_by_month: 'Month',
    view_timeline_by_year: 'Year',
    view_timeline_by_quarter: 'Quarter',
    today: 'Today',

    select_options: 'Options',
    add_option: 'Add Option',
    options_search_placeholder: 'Search for an option',
    option_input_placeholder: 'Type an option',
    option_delete: 'Delete Option',
    not_enough_options: 'Please add at least two options',
    not_null: '{field} can not be empty',

    color_whitesmoke: 'Whitesmoke',
    color_lightgray: 'Lightgray',
    color_skyblue: 'Skyblue',
    color_lightblue: 'Lightblue',
    color_azure: 'Azure',
    color_lightcyan: 'Lightcyan',
    color_honeydew: 'Honeydew',
    color_palegreen: 'Palegreen',
    color_pomonagreen: 'Pomonagreen',
    color_lightgreen: 'Lightgreen',
    color_lightyellow: 'Lightyellow',
    color_lavender: 'Lavender',
    color_lightpink: 'Lightpink',
    color_gold: 'Gold',
    color_yellow: 'Yellow',
    color_tomato: 'Tomato',
    color_beige: 'Beige',
    color_mistyrose: 'Mistyrose',

    color_red: 'Red',
    color_orange: 'Orange',
    color_green: 'Green',
    color_blue: 'Blue',
    color_purple: 'Purple',
    color_pink: 'Pink',
    color_brown: 'Brown',
    color_black: 'Black',
    color_white: 'White',
    color_gray: 'Gray',

    add_view: 'Add View',
    view_name_placeholder: 'Type view name',
    table: 'Table',
    board: 'Board',
    list: 'List',
    gallery: 'Gallery',
    timeline: 'Timeline',
    chart: 'Chart',
    create_view: 'Create View',
    delete_view: 'Delete View',
    default_status_name: 'Status',
    groups_shown: 'Visible groups',
    groups_hidden: 'Invisible groups',
    newpage: 'New Page',
    new_row: 'New',
    new_card: 'New card',

    row_item_label: 'Data item count',

    type: 'Type',
    line: 'Line',
    bar: 'Bar',
    pie: 'Pie',

    chart_axis: 'Axis',
    chart_datasets: 'Datasets',

    aggregate_to: 'Aggregate to',
    aggregate_to_individual: 'Each option',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    quarter: 'Quarter',
    year: 'Year',

    add_board_lane: '',
    groupby_setting: 'Group',
    groupby_setting_title: 'Current view groups',
    groupby: 'Group By',
    delete_groupby: 'Remove Group',
    add_option_group: 'Add a group',

    properties_setting: 'Properties',
    properties_setting_title: 'Current view properties',
    all_properties: 'All Properties',

    account_not_activated: 'Your account is not activated, the activation email has been sent to your email: {email}, please check the email and click the link to activate your account.',
    no_activation_email: 'If you have not received the activation email, you can click this button',
    still_no_activation_email: 'You can also click this button',
    resend_activation_email: 'Resend activation email',
    try_another_way: 'Try another registration method',
    already_activated: 'If you have already activated, please click this button',

    operation: 'Operation',
    delete: 'Delete',
    done: 'Done',
    nothing_in_trashbin: 'Nothing in Trashbin',
    delete_all: 'Empty Trashbin',
    restore: 'Restore',
    confirm_title: 'Confirm',
    confirm_delete_content: 'Are you sure to delete? ',
    confirm_delete_doc: 'Are you sure to delete this item? Delete from trashbin has no way to find it back.',
    confirm: 'Confirm',
    cancel: 'Cancel',
    release_to_delete: 'Release to put file to trashbin',
    remove: 'Remove',
    title_input: 'Please input title/topic for AI assistant',

    drag: 'Drag',
    to_move: 'to move',
    click: 'Click',
    to_open_menu: 'to open menu',

    member: 'Members',
    member_tooltip: 'Manage members here, invite new members and manage their permissions',
    group: 'Groups',
    group_tooltip: 'Manage groups here, create new groups and manage its group members',
    guest: 'Guests',
    guest_tooltip: 'Guest are not members of the workspace, but can access the pages you shared with them',
    user: 'User',
    role: 'Role',
    admin: 'Admin',
    invite: 'Invite',
    add_user: 'Add Member',
    invite_user: 'Invite Members',
    add_group: 'Add Group',
    leave_team: 'Leave Team',
    remove_from_team: 'Remove from Team',
    remove_self_from_org: 'Are you sure to leave this workspace? If you need to rejoin, please contact the administrator.',
    remove_last_one_from_org: 'You are the only member of the current workspace, are you sure you want to exit? If you exit this space, you will no longer be able to enter.',
    change_role_to_member: 'Are you sure to change your role from Admin to Member?',
    last_admin_in_org: 'You are the last Admin of this workspace, can not change your role to Member.',
    size: 'Size',
    workspace_name: 'Space Name',

    to_read: 'To Read',
    read: 'Read',
    note: 'Note',
    copy: 'Copy',
    copy_url: 'Copy url',

    new_document_workspace: 'New Workspace Page/Database/Slides',
    new_document_private: 'New Private Page/DataBase/Slides',
    add_ril_tooltip: 'Add links copied from other Apps or websites to Read It Later',
    ril_tooltip: 'Articles from other Apps or websites',

    doc_not_found: `This page is not exist now. Perhaps it's been deleted forever.`,
    doc_in_trashbin: 'This page is in trashbin, do you want to restore it?',

    no_access: 'No Access',
    no_access_notice: `You're not authorized to access this page.`,
    no_access_guide: 'You can contact the owner/admin of this page to request access.',

    invited_to_teams: 'You are invited to join the following teams:',
    create_or_enter_space: 'Create new workspace or enter your workspace',
    create_or_join_space: 'Create or join workspace',
    enter_space: 'Enter my workspace',
    create_space: 'Create workspace',
    create_space_title: 'You are about to use FunBlocks for:',
    personal: 'Personal',
    team: 'Team',
    personal_desc: 'FunBlocks will help you build your knowledge space, collect and build knowledge library, and use innovative writing tools to create knowledge.',
    team_desc: 'FunBlocks provides a perfect workspace for team to work together, collaborate and output results. ',
    create_team_workspace: 'Create Team Workspace',
    create_team_workspace_detail: 'Fill in team information to make team members easy to recognize',
    team_name: 'Team or Organization Name',
    select_person: 'Select a Person',

    accept: 'Accept',
    reject: 'Reject',

    propertyPrefix: 'Property',

    color: 'Color',
    text_color: 'Text Color',
    bg_color: 'Background Color',
    highlight_color: 'Highlight Color',
    default: 'Default',

    cmd_clear_marks: 'Turn into Text',
    turn_into: 'Turn into',
    paragraph: 'Paragraph',

    basic_blocks: 'Basic blocks',
    advanced_blocks: 'Advanced blocks',
    media: 'Media',
    inline: 'Inline',

    cmd_trigger: 'is what I want',
    cmd_ai_optimize: 'AI optimize text',
    cmd_ai_continue: 'AI continue write',
    cmd_ai_assistant: 'Ask AI...',
    cmd_h1: 'Heading 1',
    cmd_h2: 'Heading 2',
    cmd_h3: 'Heading 3',
    cmd_blockquote: 'Block Quote',
    cmd_new_document: 'Page',
    cmd_link_to_page: 'Link to Page',
    cmd_new_xslides: 'Slides - Full page',
    cmd_new_flow: 'Flow',
    cmd_new_xslides_inline: 'Slides - Embed',
    cmd_new_db: 'Database - Full page',
    cmd_new_db_inline: 'Database - Embed',
    cmd_new_db_view_table: 'Table View',
    cmd_new_db_view_board: 'Board View',
    cmd_new_db_view_list: 'List View',
    cmd_new_db_view_gallery: 'Gallery View',
    cmd_new_db_view_timeline: 'Timeline View',
    cmd_image: 'Image',
    cmd_image_link: 'Image Link',
    cmd_media_embed: 'Media',
    cmd_link: 'Link',
    cmd_codeblock: 'Code Block',
    cmd_codeline: 'Code Line',
    cmd_mathblock: 'Block equation',
    cmd_mathline: 'Inline equation',
    cmd_hr: 'Horizontal Rule',
    cmd_line_break: 'Line Break/Soft return',
    cmd_block_below: 'Break',
    cmd_block_above: 'Break above',
    cmd_highlight: 'Highlight',
    cmd_superscript: 'Superscript',
    cmd_subscript: 'Subscript',
    cmd_indent: 'Indent',
    cmd_outdent: 'Outdent',
    cmd_bulleted_list: 'Bulleted list',
    cmd_ordered_list: 'Ordered list',
    cmd_todo_list: 'To-do list',
    cmd_align_left: 'Align Left',
    cmd_align_center: 'Align Center',
    cmd_align_right: 'Align Right',
    cmd_align_justify: 'Align Justify',
    cmd_table: 'Table',
    cmd_table_row: 'Table Row',
    cmd_table_column: 'Table Column',
    cmd_table_delete_row: 'Delete Table Row',
    cmd_table_delete_column: 'Delete Table Column',
    cmd_table_delete: 'Delete Table',
    cmd_table_header: 'Set Table Header',
    cmd_emoji: 'Emoji',
    cmd_hslide: 'New Horizontal Slide',
    cmd_vslide: 'New Vertical Slide',
    cmd_notes: 'Speaker Notes',
    cmd_slidecolor: 'Slide Text Color',
    cmd_background: 'Image background',
    cmd_fullscreen: 'Image fullscreen',
    cmd_fullscreencover: 'Image fullscreen',
    cmd_chart: 'Chart',
    cmd_poll: 'Poll',

    cmd_trigger_desc: 'Continue with text',
    cmd_ai_optimize_desc: 'AI optimize text',
    cmd_ai_continue_desc: 'AI continues to write based on the above content',
    cmd_ai_assistant_desc: 'Review, edit, or write',
    cmd_h1_desc: 'Big section heading',
    cmd_h2_desc: 'Medium section heading',
    cmd_h3_desc: 'Small section heading',
    cmd_blockquote_desc: 'Capture a quote',
    cmd_new_document_desc: 'Embed a sub-page inside this page',
    cmd_link_to_page_desc: 'Link to an existring page',
    cmd_new_xslides_desc: 'Add a new Slides as a sub-page',
    cmd_new_xslides_inline_desc: 'Embed a Slides inside this page',
    cmd_new_flow_desc: 'Add a new Flow as a sub-page',
    cmd_new_db_desc: 'Add a new database as a sub-page',
    cmd_new_db_inline_desc: 'Add a new inline database to this page',
    cmd_new_db_view_table_desc: 'Create a database table view',
    cmd_new_db_view_board_desc: 'Create a database board view',
    cmd_new_db_view_list_desc: 'Create a database list view',
    cmd_new_db_view_timeline_desc: 'Create a database timeline view',
    cmd_new_db_view_gallery_desc: 'Create a database gallery view',

    cmd_image_desc: 'Upload an image',
    cmd_image_link_desc: 'Embed image with a link',
    cmd_media_embed_desc: 'Embed a video/audio with a link',
    cmd_link_desc: 'Add a link',
    cmd_codeblock_desc: 'Capture a code snippet',
    cmd_codeline_desc: 'Capture a code line',
    cmd_mathblock_desc: 'Display a standalone math equation',
    cmd_mathline_desc: 'Insert mathematic symbols in text',
    cmd_hr_desc: 'Visually divide blocks',
    cmd_line_break_desc: ' (Shift + Enter)',
    cmd_block_below_desc: 'Insert a block below (Ctrl/⌘ + Enter)',
    cmd_block_above_desc: 'Insert a block above (Ctrl/⌘ + Shift + Enter)',
    cmd_highlight_desc: 'Mark text with a color',
    cmd_superscript_desc: 'Display text as superscript',
    cmd_subscript_desc: 'Display text as subscript',
    cmd_indent_desc: 'Increase indentation',
    cmd_outdent_desc: 'Decrease indentation',
    cmd_bulleted_list_desc: 'Create a simple bulleted list',
    cmd_ordered_list_desc: 'Create a simple numbered list',
    cmd_todo_list_desc: 'Track tasks with a to-do list',
    cmd_align_left_desc: 'Align text to the left',
    cmd_align_center_desc: 'Align text to the center',
    cmd_align_right_desc: 'Align text to the right',
    cmd_align_justify_desc: 'Justify text',
    cmd_table_desc: 'Add a simple table to the page',
    cmd_table_row_desc: 'Insert a table row',
    cmd_table_column_desc: 'Insert a table column',
    cmd_table_delete_row_desc: 'Delete table row',
    cmd_table_delete_column_desc: 'Delete table column',
    cmd_table_delete_desc: 'Delete this table and all its data',
    cmd_table_header_desc: 'Set current row as table header',
    cmd_emoji_desc: 'Select and insert Emoji',
    cmd_bold_desc: 'Bold',
    cmd_italic_desc: 'Italic',
    cmd_copy_desc: 'Copy',
    cmd_strikethrough_desc: 'Strike through',
    cmd_hslide_desc: 'Add horizontal slide separator',
    cmd_vslide_desc: 'Add vertical slide separator',
    cmd_notes_desc: 'Add speaker notes',
    cmd_slidecolor_desc: 'Set current slide text color',
    cmd_background_desc: 'Set image as slides background',
    cmd_fullscreen_desc: 'Set image fullscreen',
    cmd_fullscreencover_desc: 'Set image fullscreen cover',
    cmd_chart_desc: 'Add chart with csv data',
    cmd_poll_desc: 'Add poll/test for online seminar',
    cmd_iframe_desc: 'Embed web page to slide',
    cmd_iframe: 'Embed web page',

    missing_required_data: 'Missing required info',
    missing_one_data: 'Please input at least one piece of information.',

    text: 'Text',
    alt_text: 'Caption',
    link: 'Url',
    upload_file: 'Upload File',
    upload_failed: 'Upload failed, please try again later',
    poll_desc: 'Poll description',
    chart_type: 'Result chart type',
    no_enough_options: 'No enough options',
    poll_responses: 'Responses',
    poll_result: 'Poll Result',
    data_source: 'Data source',
    csv_data: 'CSV Data',
    csv_file: 'CSV File',
    csv_url: 'CSV Url',
    rows: 'Rows',
    columns: 'Columns',
    show_csv_example: 'Show CSV data sample',
    data_invalid: 'Data invalid',
    doc_saved: 'Doc Saved!',
    doc_save_failed: 'Failed to save doc',
    save_shortcut: 'To save doc: Ctrl/Cmd + S',
    editor_slash_hint: `Type '/' for commands`,

    askAI: 'Choose an AI task below, or tell AI how to handle the selected content...',
    askAI_next: 'Choose an action below, or tell AI what to do next...',
    askAI_doing: 'AI is writing ',
    draft_placeholder: 'Please enter the subject and specific requirements (such as word count, main points, etc.)...',
    fill_in_selected: 'Fill in selected text',
    sendAI: 'Ask AI',
    submitAI: 'Confirm',
    reset_input: 'Reset',
    draft_artile: 'Write a',
    topic_label: 'about',
    outline_prompt: 'You are going to write an {article} with the subject: ```{topic}```, key points: ```{key_points}```. Please draw up a clear outline',
    draft_more_type: 'Article type',

    selection_text: 'Selection text:',
    ai_response: 'AI wrote:',

    copy_generated_content: 'Copy',
    replace_selection: 'Accept',
    replace_title: 'Replace title',
    insert_title: 'Insert title',
    insert_below: 'Insert below',
    replace: 'Replace',
    insert: 'Insert',
    continue_writing: 'Continue writing...',
    make_longer: 'Make longer',
    try_again: 'Try again',
    discard: 'Discard',
    confirm_close_ai: 'Are you sure to close AI assistant? The unsaved AI generated content will be discarded',
    confirm_discard_ai_content: 'Are you sure to step back? The unsaved AI generated content will be discarded',
    confirm_no_wait: `The AI assistant is writing, are you sure you won't have to wait a while?`,
    text_too_long: 'The selection text is too long',
    no_text: 'No content for AI',
    ai_timeout: 'AI timeout',
    should_select_item: 'Please select item from action menu below',
    should_text_or_select_item: 'Please select item from action menu below or text to ask AI directly',
    ai_response_warning: 'AI response can be inaccurate or misleading',

    bold: 'Bold',
    italic: 'Italic',
    underline: 'Underline',
    strikethrough: 'Strike through',
    comment: 'Comment',
    keyboard: 'Keyboard',
    align: 'Align',

    editor_placeholder_title: 'Untitled',
    editor_placeholder_p: `Type '/' for commands`,

    create_new_db_view: 'Create New Database View',
    create_new_slides: 'Create New Slides',
    choose_existing_slides: 'Choose Existing Slides',
    page_chooser_title: 'Choose ',
    choose_page_to_link: 'Choose a page to link',
    editor_new_page_desc: 'Press Enter to continue with an empty page or click to choose a specific typed page',
    data_editor_new_page_desc: 'Click here to create a new empty page',
    empty_page: 'Empty',
    empty_page_with_ai: `Write "{title}" with AI`,
    empty_page_ai_prompt: `Write about {topic}`,
    empty_slides_with_ai: `Write slides "{title}" with AI`,
    empty_slides_ai_prompt: `Write a slides about {topic}`,
    db_section_title: 'Database',
    slides_section_title: 'Slides',
    flow_section_title: 'AI Whiteboard + Mindmap',

    slide_seminar_tooltip: 'Online seminars, remote synchronous interaction, support lecturer chalk, whiteboard explanation, question interaction, etc.',
    slide_present_tooltip: 'Watch/Present Slides',
    slide_edit_tooltip: 'Edit Slides',
    slides_tutor_tooltip: 'Slides edit tutorial',

    sorry_for_uninstall: "We're sorry that you've uninstalled our extension",
    please_tell_your_uninstall_reason: "To help us improve, please tell us the reason for uninstalling this extension.",
    thanks_for_feedback: "Thank you for your feedback; we will take it seriously. We hope you'll consider using our extension again!",
    please_give_reason: "Please tell us the main reason for uninstalling this extension.",
    please_give_detail: "Please provide us with your valuable suggestions and information.",
    submit: "Submit",

    install_funblocks_ai_extension: 'Install the FunBlocks AI browser extension to summon the AI assistant anytime, boosting your work and study efficiency.',
    intro_extension: 'The FunBlocks AI browser extension utilizes cutting-edge AI models (such as GPT-4, Gemini, Claude, etc.) to achieve "10x speed, high quality" in: reading, writing, social interaction, email summarization and reply, video content summarization, and more.',
    intro_funblocks: 'At the same time, FunBlocks not only offers browser plugin tools but also serves as a more comprehensive all-in-one AI platform compared to Notion. With an editor as the interface, it allows you to collaborate with AI assistants, thereby enhancing writing and learning efficiency. Give it a try!',
    copy_invite_link: 'Copy the invitation link',
    invitation_records: 'Invitation Record',
    click_to_copy_msg_link: 'Click to Copy Link & Message',
    download_extension_to_participate: 'Download Extension for free',
    welcome_to_funblocks: 'Welcome to FunBlocks AI',
    invite_to_earn: `Invite to Earn!
    Get {coins} free AI tokens for each invite
    `,
    invited_to_given: 'Invited users will also receive {coins} AI tokens',
    free_coins_awarded: 'Download now to get an extra {coins} free AI queries',
    detail: 'Detail',
    invited_frends: 'Invited Friends',
    total_rewards: 'AI Queries Reward',
    friend: 'Friend',
    reward: 'Reward',
    time: 'Time',
    flow_ask_placeholder: 'Ask anything...',
    flow_ask_question_placeholder: 'Ask question...',
    flow_ask_question_or_choose_skill_placeholder: 'Ask question or choose AI skill...',
    flow_dynamic_placeholder: 'Type your requirements...',
    flow_dynamic_with_menu_placeholder: 'Type requirements, or select prompt below...',
    flow_breakdown_placeholder: 'Type any topic to breakdown...',
    flow_brainstorming_placeholder: 'Enter theme to start brainstorming...',
    flow_link_placeholder: 'Enter an url to explore its content...',
    flow_search_placeholder: 'Search latest information from web...',
    flow_perspective_placeholder: 'Enter new perspective...',
    flow_idea_placeholder: 'Enter new idea...',
    flow_task_analysis_placeholder: 'Click to Analysis Task...',
    flow_new_task_placeholder: 'Enter new task description...',
    flow_todos_placeholder: 'Enter your task, goal or project...',
    flow_new_sub_topic_placeholder: 'Enter new subtopic...',
    flow_new_insight_placeholder: 'Enter new insight...',
    flow_mindmap_primary_branch_placeholder: 'Enter key concept/point...',
    flow_add_improve_plan_placeholder: 'Enter new improvement plan...',
    new_perspective: 'New perspective',
    new_idea: 'Add new idea',
    new_task: 'Add new task',
    remove_task: 'Delete task',
    new_sub_topic: 'Add new subtopic',
    new_insight: 'Add new insight',
    mindmap: 'Mindmap',
    mindmap_primary_branch: 'Explore key concept/point in given text',
    generate_mindmap: 'Generate a mindmap',
    summarize_tips: 'Generate a summary',
    summarize: 'Summarize',
    summary: 'Summary',
    more_ai_menu: 'More AI actions',

    click_to_execute: 'Click to {action}',
    node_action_dynamic: 'run prompt',
    node_action_ask: 'ask question',
    node_action_brainstorming: 'brainstorm',
    node_action_breakdown: 'breakdown',
    node_action_todos: 'generate todo list',
    node_action_search: 'search web',
    node_action_link: 'summarize web page',
    node_action_dynamic_with_menu: 'run prompt',
    node_action_ask_question: 'ask question',

    toggle_options: 'Toggle options',
    change_prompt_querytype: 'Click to switch AI mode',

    describe_image: 'Describe image with a wise mind',
    breakdown_topics: 'Breakdown Topics',
    breakdown_topics_tips: 'Break complex topics into subtopics',
    ask_question: 'Ask a Question',
    ask_question_tips: 'Ask a question, AI helps you optimize the question',
    dynamic: 'Do something',
    dynamic_tips: 'Make a request, AI will interact with you in detail',
    brainstorming: 'Brainstorming',
    brainstorming_tips: 'AI helps you brainstorm, presenting ideas in a mind map',
    todo_list: 'To-Do list',
    empty_todo_list: 'Create a new To-Do list',
    empty_todo_list_tips: 'Add your first To-Do item by clicking the button below',
    generate_todo_list: 'Generate To-Do list',
    generate_todo_list_tips: 'Task breakdown, generate a To-Do list',
    search_web: 'Search the web',
    explain: 'Explain',
    selectable_context: 'Context',
    add_note: 'Add a note',
    add_image: 'Add an image',
    add_link: 'Add a link',
    add_caption: 'Add caption',
    to_note: 'Copy to editable sticky note',
    as_note: 'convert to sticky note',
    to_todolist: 'Copy to To-do list',
    copy_node: 'Copy node',
    save_to_workspace: 'Save as a document in your workspace, ready for further AI Assistant editing',
    export_to_pdf: 'Export to PDF',
    paste_as_node: 'Paste',
    type_note: 'Type a note',
    group_nodes: 'Group the selected nodes',
    ungroup_nodes: 'Ungroup',
    remove_from_group: 'Move node out of this group',
    redo: 'Redo',
    undo: 'Undo',
    export_to_image: 'Export as image. Share your exported images with friends and earn free AI access tokens!',
    add_private_llm_key: 'Add Private LLM API Key',
    set_ai_model: 'Set LLM model',
    set_default_ai_model: 'Set default LLM model',
    get_models_test_token: 'Get Models/Test Key',
    token_valid: 'Key and Endpoint valid',
    token_invalid: 'Key or Endpoint invalid',
    set_lang_as_context: 'Context-adaptive',
    set_ai_response_lang: 'Set AI response language',
    tell_me_more: 'Explore further...',
    selectable_ideas: 'Selectable ideas',
    hide_action_box: 'Hide',
    get_more: "Load more",
    remove_node: 'Remove node',
    expand: 'Expand',
    collapse: 'Collapse',
    search_results: 'Search engine results',
    query_analysis: 'Analysis of the original question',
    optimized_query: 'Optimized questions',
    show_detail: 'Show detail',
    hide_detail: 'Hide detail',

    new_board: 'Create new board',
    edit_title_placeholder: 'Edit title',
    please_set_ai: 'Please login AI Flow or Setup 3rd party LLM Service provider',
    please_set_api: 'You have selected a third-party LLM service provider, but the API parameters are not set correctly.',
    exceed_msg_limit: `You've reached your daily {model_level} LLM limit. Please upgrade your FunBlocks AI plan for a higher quota or choose another model to continue.`,
    no_aigc_content: 'AI failed to generate content.',
    copy_to_edit: 'Create Editable Copy',
    flow_info_login: 'Please log in',
    flow_info_extension_not_supported_for_current_browser: 'Please use Chrome or Edge browser to install the FunBlock AI Extension.',
    flow_info_copy_to_editable: 'The current whiteboard is read-only. To make changes, please copy an editable version.',
    flow_info_saved_to_workspace: "Content saved to your workspace. Click the button below to continue editing.",
    to_workspace: 'Continue Editing',
    copy_to_editable: 'Copy',
    flow_settings: 'Settings',
    settings_funblocks_desc:
      'After registering a FunBlock account, you will receive a trial of 40 queries to use the AI assistant and enjoy 20 free AI assistant queries every day. Additionally, you have the option to upgrade to the FunBlock AI membership plan for unlimited access to the AI assistant.',
    invalid_api_for_image: 'AI Assistant does not currently support querying image information from third-party LLM services. Please select FunBlocks AI service.',
    ai_insights: 'Generate exploration space',
    summuary_insights: 'Summarized insights',

    cut: 'Cut',
    paste: 'Paste',
    paste_as_plain_text: 'Paste as plain text',
    funblocks_aiflow: 'FunBlocks AIFlow',
    aiflow_slogan: 'Work, Learn and Explore with',
    qr_code_tips: 'Scan the QR Code to continue exploring ...',
    share_tip: 'Share your exciting AI exploration with friends and get free AI access.',
    ai_token: 'AI Token',
    ai_token_desc: 'Standard AI queries cost 1 AI token, advanced AI queries cost 5 AI tokens',
    your_ai_token_balance: 'You AI Token Balance: ',
    ai_tokens_income: 'AI Token Income Transations',
    show_latest_records: 'Only show the latest {count} items',
    wanna_more_ai_tokens: 'Get more AI tokens: ',
    date: 'Date',
    count: 'Amount',
    income_item: 'Income Item',
    new_user_trial: 'Trial',
    invited_by_friend: 'Invited By Friend Reward',
    invite_friend: 'Invite Friend Reward',
    shared_aigc_visited: 'Shared AIGC Visited Reward',
    share_aigc: 'Share AI Flow works with friends or community',
    share_aigc_tips: 'Each person who reads the AIFlow work through your shared link will earn you 10 AI Tokens as a reward. You can share the link by clicking the Share button or export the work as an image before sharing.',
    choose_flow_board: 'Create/Choose a board',
    priority: 'Priority',
    due_date: 'Due date',
    breakdown_task: 'Break down into subtasks',
    copy_content: 'Copy content to clipboard',
    save_page: 'Save',

    advanced_settings: 'More settings',
    type_or_select_placeholder: 'Type or select from the right...',

    topic_type: 'Scenario',
    thematic_learning: "Thematic Learning",
    personal_growth: "Personal Growth",
    startup_idea: "Startup Idea",
    bussiness_analysis: "Business Analysis",
    investment_analysis: "Investment Analysis",
    product_innovation: "Product Innovation",
    solutions_design: "Solutions Design",
    project_planning: "Project Planning",
    marketing_strategies: "Marketing Strategies",
    branding_building: "Branding Building",
    advertisiing_compaign_ideas: "Advertising Campaign Ideas",
    root_cause_analysis: "Root Cause Analysis",
    team_management: "Team Management",
    operation_optimization: "Operation Optimization",
    innovative_ideas: "Generate Innovative Ideas",
    event_planning: "Event Planning",
    decision_analysis: "Decision Analysis",
    meeting_disucssions: "Meeting Discussions",
    travel_experiences: "Travel Experiences",
    writing_ideas: "Develop a writing framework",
    research_topics: "Research Topics",
    curriculum_design: "Curriculum Design",
    social_issue_solutions: "Social Issue Solutions",

    thinking_model: 'Thinking model',
    swot_analysis: "SWOT Analysis",
    business_model_canvas: "Business Model Canvas",
    fivew1h_method: "5W1H Method",
    scamper_method: "SCAMPER Method",
    six_thinking_hats: "Six Thinking Hats",
    pdca: "PDCA Cycle",
    systems_thinking: "Systems Thinking",
    steep_analysis: "STEEP Analysis",
    five_forces: "Five Forces Analysis",
    four_p: "4P Marketing Mix",
    triz: "Theory of Inventive Problem Solving",
    rephrazing: "Problem Rephrazing",
    learning_pyramid: "Learning Pyramid",
    kwl: "KWL Map",
    changing_perspectives: "Changing Perspectives",
    reverse_thinking: "Reverse Thinking",
    role_playing: "Role Playing",
    mckinsey_7S_framework: "McKinsey 7S Framework",
    value_proposition_design: "Value Proposition Design",

    payment: "Payment",
    flow_exported_image_watermark_qrcode: "Included QR code in the downloaded AI Flow image",
    flow_exported_image_watermark_qrcode_tips: "The QR code contains your invitation code. If users visit the link or register through it, you will receive corresponding AI Token rewards",

    selected_node: 'Only parent node',
    selected_node_and_descendants: "Parent node and its descendants",
    grouped_nodes: 'Grouped nodes',
    reflect_and_improve: 'Reflect and improve',
    reflect: 'Reflect',
    improvement_plan: 'Improvement plan',
    improve_now: 'Improve now',
    add_improvement_plan: 'Add Item',
    failed_load_image: 'Failed to get image data',

    more_info_form: 'Please provide more information',
    generate_content_format: 'Content format',
    ai_infered_task_type_description: 'The AI infers the task type from the description, and subsequent information depends on this. If incorrect, please reselect the task type and click the right button to retry.',
    underlying_needs: 'Underlying Goal/Needs',
    softbreak_tips: "Use 'Shift + Enter' for new line",
    "keyboard_save_hint": "Control/Command + s: Save board",
    "paste_to_board_hint": "You can paste text, links, and images directly onto the whiteboard.",
    'flow_settings_mouse_touch': 'Mouse and Touch Settings',
    'flow_settings_drag_behavior': 'Drag Behavior',
    'flow_settings_drag_pan': 'Drag to Pan Canvas',
    'flow_settings_drag_select': 'Drag to Select Nodes',
    'flow_settings_scroll_behavior': 'Scroll Behavior',
    'flow_settings_scroll_zoom': 'Scroll to Zoom Canvas',
    'flow_settings_scroll_pan': 'Scroll to Pan Canvas',
    'flow_settings_pinch_zoom': 'Enable Pinch to Zoom',
    'flow_settings_key_mouse_combo': 'Keyboard and Mouse Combinations',
    'flow_settings_selection_key': 'Selection Key',
    'flow_settings_selection_hint': '{key} + Drag: Select Nodes',
    'flow_settings_pan_key': 'Pan Key',
    'flow_settings_pan_hint': '{key} + Drag: Pan Canvas',
    'flow_settings_zoom_key': 'Zoom Key',
    'flow_settings_zoom_hint': '{key} + Scroll: Zoom Canvas',
    'flow_settings_delete_node': 'Enable Backspace to Delete Node',
    'flow_settings_scroll_pan_horizontal_hint': 'Shift + Scroll: Pan Horizontal',
    'pinch_zoom_tip': 'Pinch: Zoom Canvas',

    "flow-editor": "Welcome to FunBlocks AIFlow!",
    "flow-boards": "View or Create Whiteboards",
    "dynamic-ai-nodes": "Start AI Interaction",
    "more-ai-nodes": "More AI Interaction Nodes",
    "non-ai-nodes": "Notes, To-Do Lists",
    "model-selector": "Select AI Model",
    "output-lng-selector": "Set AI Output Language",
    "board-operations": "Save, Undo, Redo",
    "share-board": "Export or Share Whiteboard, Earn Free AI Access Rewards",
    "download-extension": "Download FunBlocks AI Browser Extension to Boost Your Work and Study Efficiency",
    "settings": "App Settings",
    "board-viewport-operations": "Adjust Whiteboard View",
    "board-interaction-hint": "Whiteboard Mouse/Keyboard Hint",
    "minimap": "Minimap Navigation",
    temp_notesbook: "Scrapbook",
    to_temp_notebook: 'Add to scrapbook',
    merge_notes: 'Merge clippings',
    select_all: 'Select all',
    deselect_all: 'Deselect all',
    delete_selected_clips: 'Delete selected clips',
    regenerate_tooltip: 'Will generate a new node with the selected model',
    generated_with: 'Generated by',
    regenerate_with: 'You can regenerate using',
    save_to_prompt: 'Save prompt',
    space_intro: 'FunBlocks Workspace',
    to_workspace: 'To Workspace',
    what_is_workspace: 'What is FunBlocks Workspace?',
    workspace_intro: `FunBlocks Workspace is an all-in-one knowledge hub where you can create, organize, and collaborate on documents, presentations, and mind maps.  Powered by an AI assistant that learns and adapts to your needs, the workspace becomes your personalized knowledge base, boosting your productivity and streamlining creative workflows.`,
    generate_slides: 'Generate Presentation',
    node_export: 'Export',
    share_prompt_title: 'Share an amazing FunBlocks AI Prompt application: {prompt}. Open this link and click "Run" to let the AI assistant work its magic for you!',
    add_promo_code: 'Add promotion code',
    enter_promo_code: 'Promotion code',
    apply: 'Apply',
    layout_boards: 'Automatic node arrangement',
    optimize_question: 'Optimize question',
    optimize_search: 'Optimize search keywords',
    to_original_page: 'To original page',
    add_funblocks_page: 'Add a FunBlocks page or slides',
    page_chooser_title: 'Choose a FunBlocks page or slides',
    email_opt_in: 'I agree to receive application-related emails',
    receive_update_emails: 'Receive application update emails',
    receive_update_emails_tips: 'You will receive emails about new features and important updates.',
    flow_info_action_requires_specific_models: 'This action requires specific models: {models}. Please switch to the corresponding model.',
    resize_proportionally: 'Resize proportionally',
    infograph: 'Infograph',
    infograph_tips: 'Generate Flowchart, Slide, Cornell notes, or Infographics from node content',
    all_hints: 'All Hints',
    show_all_hints: 'Show All Hints',
    'flow_settings_show_examples': 'Show input examples above the input box',
    'flow_settings_show_hint_panel': 'Show interaction hint on bottom of whiteboard',
    choose_image: 'Choose image',
    paste_image_url: "Paste image url",
    or: 'or',
    zoom_in: 'Zoom in',
    zoom_out: 'Zoom out',
    fullscreen: 'Full Screen'
  },
};
